
export type Language = {
  code: string
  name: string
}

export const languages: Language[] = [
  {code: 'en', name: $localize`English`},
  {code: 'es', name: $localize`Spanish`},
  {code: 'ca', name: $localize`Catalan`}
]

export function getLanguage(code: string): Language {
  return languages.find(l => l.code == code) || languages[0]
}
