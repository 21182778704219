import {Component, Inject, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
    MAT_DIALOG_DATA, MatDialog, MatDialogConfig,
    MatDialogModule, MatDialogRef,
} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {UserPlayerComponent} from "./user-player.component";
import {UserPlayerResponse} from "../../model/responses";
import {ShareService} from "../../service/ui/share.service";
import {UserPlayerTagSelfDialogComponent} from "./user-player-tag-self-dialog.component";
import {DialogService} from "../../service/ui/dialog.service";
import {AuthedUserService} from "../../service/authed-user.service";

@Component({
    template: `
        <h1 mat-dialog-title i18n>Tag {{data.userPlayer.name}} as user</h1>
        <mat-dialog-content>
            <p i18n>When a player is tagged as a user, all their references in your game plays will be linked to their user profile.</p>
            <button mat-raised-button color="primary" style="width: 100%" (click)="invite()" i18n>Invite {{data.userPlayer.name}}</button>
            <button mat-stroked-button color="primary" style="width: 100%; margin-top: 16px" (click)="tagSelf()" i18n>I am {{data.userPlayer.name}}</button>
        </mat-dialog-content>
        <div mat-dialog-actions class="row space-between">
            <button mat-button cdkFocusInitial mat-dialog-close i18n>Close</button>
        </div>
    `,
    styles: [`
        .mat-mdc-dialog-content {
            max-height: unset !important;
        }
    `],
    standalone: true,
    imports: [
        CommonModule,
        MatDialogModule, MatButtonModule,
        UserPlayerComponent,
    ]
})
export class UserPlayerTagDialogComponent {

    currentUser = this.authedUserService.getUserData()

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { userPlayer: UserPlayerResponse, inviteCode: string },
        private matDialogRef: MatDialogRef<UserPlayerTagDialogComponent>,
        private dialog: MatDialog,
        private dialogService: DialogService,
        private shareService: ShareService,
        private authedUserService: AuthedUserService,
    ) {
    }

    invite() {
        this.shareService.shareLink(
            $localize`Join my Ludoya player profile!`,
            $localize`I started building your player profile. Click the link to join!`,
            `player/${this.data.userPlayer.id}?invite=${this.data.inviteCode}`
        )
    }

    tagSelf() {
        const dialogConfig: MatDialogConfig = {
            data: {
                userPlayer: this.data.userPlayer,
                inviteCode: this.data.inviteCode,
            },
        }
        this.dialogService.fullScreenIfMobile(dialogConfig)
        const dialog = this.dialog.open(UserPlayerTagSelfDialogComponent, dialogConfig)
        this.dialogService.onDialogOpened(dialog)
        dialog.afterClosed().subscribe(() => {
            this.data.userPlayer!.linkedUser = this.currentUser
        })
        this.matDialogRef.close()
    }
}
