import {Injectable} from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {
    MeetupResponse,
    Page,
    IdResponse,
    UserMeetupsResponse,
    MeetupsResponse,
    PlannedPlayResponse, PlannedPlaysResponse
} from "../../model/responses";
import {
    MeetupAttendanceRequest,
    MeetupRequest,
    PlannedPlayParticipantsRequest,
    PlannedPlayRequest
} from "../../model/requests";

@Injectable()
export class MeetupService {

    constructor(
        private http: HttpClient,
    ) {
    }

    searchMeetups(fromLatitude: number, fromLongitude: number, pageSize: number, pageIndex: number): Observable<Page<MeetupResponse>> {
        const params = {
            fromLatitude: fromLatitude,
            fromLongitude: fromLongitude,
            pagination: pageSize + ',' + pageIndex
        }
        return this.http.get<Page<MeetupResponse>>(`${environment.apiUrl}/meetups/search`, {params: params});
    }

    getAllEvents(fromLatitude?: number, fromLongitude?: number): Observable<MeetupsResponse> {
        let params = new HttpParams()
        if (fromLatitude && fromLongitude) {
            params = params.append('fromLatitude', fromLatitude.toString());
            params = params.append('fromLongitude', fromLongitude.toString());
        }
        return this.http.get<MeetupsResponse>(`${environment.apiUrl}/meetups/all`, {params: params});
    }

    getMeetups(): Observable<UserMeetupsResponse> {
        return this.http.get<UserMeetupsResponse>(`${environment.apiUrl}/meetups`);
    }

    getMeetup(id: string): Observable<MeetupResponse> {
        return this.http.get<MeetupResponse>(`${environment.apiUrl}/meetups/${id}`);
    }

    createMeetup(meetup: MeetupRequest, attachment?: Blob): Observable<IdResponse> {
        const formData = new FormData();
        formData.append('request', JSON.stringify(meetup));
        if (attachment) {
            formData.append(`attachment-0`, attachment);
        }
        return this.http.post<IdResponse>(`${environment.apiUrl}/meetups`, formData);
    }

    updateMeetup(id: string, meetup: MeetupRequest, attachment?: Blob): Observable<any> {
        const formData = new FormData();
        formData.append('request', JSON.stringify(meetup));
        if (attachment) {
            formData.append(`attachment-0`, attachment);
        }
        return this.http.put(`${environment.apiUrl}/meetups/${id}`, formData);
    }

    deleteMeetup(id: string): Observable<any> {
        return this.http.delete(`${environment.apiUrl}/meetups/${id}`);
    }

    joinMeetup(id: string): Observable<any> {
        return this.http.post(`${environment.apiUrl}/meetups/${id}/join`, null);
    }

    leaveMeetup(id: string): Observable<any> {
        return this.http.post(`${environment.apiUrl}/meetups/${id}/leave`, null);
    }

    inviteToMeetup(id: string, userId: string): Observable<any> {
        return this.http.post(`${environment.apiUrl}/meetups/${id}/invite`, {userId: userId});
    }

    updateAttendance(id: string, assistance: MeetupAttendanceRequest): Observable<any> {
        return this.http.put(`${environment.apiUrl}/meetups/${id}/attendance`, assistance);
    }

    getMeetupPlannedPlays(id: string): Observable<PlannedPlaysResponse> {
        return this.http.get<PlannedPlaysResponse>(`${environment.apiUrl}/meetups/${id}/planned-plays`);
    }

    getPlannedPlay(id: string): Observable<PlannedPlayResponse> {
        return this.http.get<PlannedPlayResponse>(`${environment.apiUrl}/planned-plays/${id}`);
    }

    createPlannedPlay(plannedPlay: PlannedPlayRequest): Observable<any> {
        return this.http.post(`${environment.apiUrl}/planned-plays`, plannedPlay);
    }

    updatePlannedPlay(id: string, plannedPlay: PlannedPlayRequest): Observable<any> {
        return this.http.put(`${environment.apiUrl}/planned-plays/${id}`, plannedPlay);
    }

    deletePlannedPlay(id: string): Observable<any> {
        return this.http.delete(`${environment.apiUrl}/planned-plays/${id}`);
    }

    addPlannedPlayParticipants(id: string, request: PlannedPlayParticipantsRequest): Observable<any> {
        return this.http.post(`${environment.apiUrl}/planned-plays/${id}/participants`, request);
    }

    removePlannedPlayParticipant(id: string, userId: string): Observable<any> {
        return this.http.delete(`${environment.apiUrl}/planned-plays/${id}/participants/${userId}`);
    }

    setPlannedPlayPlay(id: string, playId: string): Observable<any> {
        return this.http.put(`${environment.apiUrl}/planned-plays/${id}/play`, {playId: playId});
    }
}
