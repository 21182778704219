
import {Component, EventEmitter, Input, Output} from "@angular/core";
import { WritePostFormComponent } from "./write-post-form.component";
import { MatIconModule } from "@angular/material/icon";
import { MatExpansionModule } from "@angular/material/expansion";

@Component({
    selector: 'cb-write-post-panel',
    template: `
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <div class="row start align-center">
          <mat-icon>chat</mat-icon>
          <span class="panel-heading" i18n>Write post</span>
          <span class="optional-heading" i18n>(optional)</span>
        </div>
      </mat-expansion-panel-header>
      <div>
        <cb-write-post-form [postContentRequired]="false"
                            [postContent]="postContent"
                            [files]="files"
                            (postContentChange)="postContentChange.emit($event)"
                            (filesChange)="filesChange.emit($event)"
        ></cb-write-post-form>
      </div>
    </mat-expansion-panel>
  `,
    styles: [`
    .panel-heading {
      margin-left: 8px;
      font-size: 1rem;
      font-weight: 400;
    }

    .optional-heading {
      margin-left: 8px;
      font-size: 1rem;
      font-weight: 400;
      color: #b1b8be;
    }
  `],
    standalone: true,
    imports: [MatExpansionModule, MatIconModule, WritePostFormComponent]
})
export class WritePostPanelComponent {

  @Input()
  postContent = ''
  @Input()
  files: Blob[] = []

  @Output()
  postContentChange = new EventEmitter<string>()
  @Output()
  filesChange = new EventEmitter<Blob[]>()
}
