import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {BoardGameBasicResponse} from "../model/responses";
import {BreakpointObserver} from "@angular/cdk/layout";
import {getUserGameOwnershipData, UserGameOwnership} from "../model/user-game-ownership";
import {MatIconRegistry, MatIconModule} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";
import {PlayDatePipe} from '../play/play-date.pipe';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CommonModule} from '@angular/common';
import {NavigationService} from "../service/ui/navigation.service";
import {CurrentViewDataService} from "../service/current-view-data.service";

@Component({
    selector: 'cb-game-grid',
    template: `
        <div class="row center align-center" *ngFor="let someGames of gameGrid"
             [style.margin-left.px]="-gamesMargin" [style.margin-right.px]="-gamesMargin">
            <div *ngFor="let g of someGames" class="container column end align-center flex"
                 [style.cursor]="g ? 'pointer' : 'default'"
                 [style.max-width.px]="gamesMaxSize"
                 [style.margin.px]="gamesMargin"
                 [class.expansion]="g?.displayAsExpansion == true"
                 (click)="selectGame(g)">
                <img *ngIf="g" [src]="g.game.image.previewUrl" [alt]="g.game.name"/>
                <div *ngIf="g?.bottomLabel && g.bottomLabel != '0'" class="bottom-label">
                    {{ g.bottomLabel }}
                </div>
                <span *ngIf="g?.topRightNumber" class="top-right-label">
                    {{ g.topRightNumber }}
                </span>
                <div *ngIf="g?.tags" class="tags column align-start">
                    <span *ngFor="let tag of g.tags" class="tag">
                        {{ tag }}
                    </span>
                </div>
                <span *ngIf="g?.bottomRightLabel" class="bottom-right-label">
                    {{ g.bottomRightLabel }}
                </span>
                <mat-icon *ngIf="g?.ownership" class="ownership-icon"
                          matTooltip="{{getUserGameOwnershipData(g?.ownership).name}}">
                    {{ getUserGameOwnershipData(g?.ownership).icon }}
                </mat-icon>
                <div *ngIf="groupExpansions && g?.expansions && g.expansions?.length" class="expansion-badge"
                     (click)="changeShowExpansions(g)">
                    <span *ngIf="!g.showExpansions">+{{ g?.expansions?.length }}</span>
                    <span *ngIf="g.showExpansions">X</span>
                </div>
            </div>
        </div>
    `,
    styles: [`
        .container {
            position: relative;
            min-width: 50px;
            //max-width: 250px;

            transition: all .2s ease-in-out;
        }

        .container:after {
            content: "";
            display: block;
            padding-bottom: 100%; /* The padding depends on the width, not on the height, so with a padding-bottom of 100% you will get a square */
        }

        .container:hover {
            transform: scale(1.06);
        }

        .container img {
            position: absolute; /* Take your picture out of the flow */
            width: 100%; /* This if for the object-fit */
            height: 100%; /* This if for the object-fit */
            object-fit: cover; /* Equivalent of the background-size: cover; of a background-image */
            //object-position: center;
            border-radius: 7px;
            box-shadow: 2px 2px 4px #000000;
        }

        .bottom-label {
            position: absolute;
            bottom: 0;
            background-color: #141F29aa;
            color: white;
            text-align: center;
            border-radius: 10px 10px 0 0;
            //width: 20%;
            max-width: 80%;
            //margin: 0 auto;
            font-size: 10px;
            line-height: 1.2;
            padding: 4px 8px 2px 8px;
            z-index: 25;
        }

        .top-right-label {
            width: 16px;
            height: 16px;
            text-align: center;
            vertical-align: middle;
            position: absolute;
            right: -4px;
            top: -4px;
            background-color: #141F29aa;
            color: #ffffff;
            font-weight: 700;
            line-height: 16px;
            //padding: 2px;
            border: 2px solid #ffffff;
            border-radius: 50%;
            font-size: 0.7rem;
            z-index: 25;
        }

        .tags {
            position: absolute;
            left: 1px;
            top: 1px;
            z-index: 25;

            .tag {
                background-color: #141F29aa;
                color: #ffffff;
                font-size: 0.7rem;
                padding: 2px 4px;
                margin: 1px;
                border-radius: 4px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 72px;
            }
        }

        .bottom-right-label {
            text-align: center;
            position: absolute;
            right: 0;
            bottom: 0;
            background-color: #141F29aa;
            color: #ffffff;
            padding: 0 2px;
            border-radius: 4px;
            font-size: 0.7rem;
        }

        .ownership-icon {
            position: absolute;
            color: var(--panel-background-color);
            border-bottom-left-radius: 8px;
            border-top-right-radius: 4px;
            top: 0;
            right: 0;
            background-color: var(--primary-color);
            z-index: 20;
            padding: 4px
        }

        .expansion-badge {
            width: 24px;
            height: 24px;
            position: absolute;
            top: calc(50% - 15px);
            right: 4px;
            background-image: url('/assets/arrow.svg');
            cursor: pointer;
            z-index: 25;

            span {
                color: #000000;
                font-weight: 700;
                font-size: 12px;
                position: absolute;
                top: 50%;
                left: 40%;
                transform: translate(-50%, -50%);
            }
        }

        .mat-icon {
            width: 16px;
            height: 16px;
            font-size: 16px;
        }

        .expansion {
            transform: scale(0.85);
            box-shadow: 0 0 8px #ffffff;
        }
    `],
    standalone: true,
    imports: [CommonModule, MatIconModule, MatTooltipModule, PlayDatePipe]
})
export class GameGridComponent implements OnInit, OnChanges {

    @Input()
    games: GridGame[] = []

    @Input()
    gamesPerRow: number | undefined

    @Input()
    maxRows: number | undefined

    @Input()
    gamesMaxSize: number = 250
    gamesMargin: number = 4

    @Input()
    groupExpansions: boolean = false

    @Output()
    onGameSelected = new EventEmitter<string>()

    gameGrid: GridGame[][] = []

    constructor(
        private breakpointObserver: BreakpointObserver,
        private currentViewDataService: CurrentViewDataService,
        iconRegistry: MatIconRegistry,
        sanitizer: DomSanitizer
    ) {
        iconRegistry.addSvgIcon('book', sanitizer.bypassSecurityTrustResourceUrl('assets/book.svg'))
    }

    ngOnInit(): void {
        this.breakpointObserver.observe([
            '(max-width: 500px)',
            '(max-width: 800px)',
            '(max-width: 1200px)',
            '(max-width: 2000px)',
            '(max-width: 3500px)',
        ]).subscribe(result => {
            const games = this.groupExpansions ? this.prepareGroupsByExpansion(this.games) : this.games
            this.prepareGameGrid(games);
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes['games'] && !changes['groupExpansions']) {
            return
        }

        const games = this.groupExpansions ? this.prepareGroupsByExpansion(this.games) : this.games
        this.prepareGameGrid(games);
    }

    prepareGroupsByExpansion(games: GridGame[]): GridGame[] {
        const expansions = games.filter(game => game.isExpansion)
        for (const game of games) {
            game.expansions = []
        }
        for (const expansion of expansions) {
            for (const baseGameId of expansion.expands!) {
                const baseGame = games.find(g => g.game.id === baseGameId)
                baseGame?.expansions?.push({...expansion, displayAsExpansion: true})
            }
        }

        const newGameList: GridGame[] = []
        const displayedExpansionIds = new Set<string>()
        for (const g of games) {
            if (!displayedExpansionIds.has(g.game.id)) {
                newGameList.push(g)
            }
            if (!g.isExpansion && g.expansions && g.expansions.length > 0) {
                if (g.showExpansions) {
                    newGameList.push(...g.expansions)
                }
                for (const gameExp of g.expansions) {
                    displayedExpansionIds.add(gameExp.game.id)
                }
            }
        }
        return newGameList.filter(g => !displayedExpansionIds.has(g.game.id) || g.displayAsExpansion)
    }

    prepareGameGrid(games: GridGame[]) {
        let gamesPerRow = 3;
        if (this.gamesPerRow) {
            gamesPerRow = this.gamesPerRow;
        } else {
            let width = window.innerWidth;
            // GLOBAL WIDTH PATCH (SEARCH AND REMOVE THIS COMMENT WHEN DOING THINGS BETTER)
            if (width > 700) {
                width = 700
            }

            let gameWidth = Math.min(this.gamesMaxSize, 150)
            gamesPerRow = Math.max(Math.floor(width / gameWidth), 3)
            this.gamesMargin = (width / gamesPerRow) / 25
        }
        if (this.maxRows && games.length > 0) {
            // Reduce the number of games per row if we can fit them better in the maxRows
            while (games.length <= (gamesPerRow - 1) * this.maxRows && games.length > gamesPerRow * (this.maxRows - 1)) {
                gamesPerRow--
            }
        }

        this.gameGrid = []
        if (
            games.length > gamesPerRow && games.length % gamesPerRow > 0 &&
            (!this.maxRows || Math.floor(games.length / gamesPerRow) < this.maxRows)
        ) {
            this.gameGrid[Math.floor(games.length / gamesPerRow)] = Array(gamesPerRow)
        }
        games.forEach((game, index) => {
            const i = index % gamesPerRow;
            const j = Math.floor(index / gamesPerRow);
            if (this.maxRows && j >= this.maxRows) {
                return;
            }
            if (!this.gameGrid[j]) {
                this.gameGrid[j] = [];
            }
            this.gameGrid[j][i] = game;
            if (game.tags && game.tags.length > 4) {
                game.tags = game.tags.slice(0, 4)
                game.tags.push('...')
            }
        });
    }

    changeShowExpansions(game: GridGame) {
        game.showExpansions = !game.showExpansions;
        this.prepareGameGrid(this.prepareGroupsByExpansion(this.games))
    }

    selectGame(game: GridGame) {
        //this.onGameSelected.emit(game.game.slug);
        //this.navigationService.navigate(['/g', game.game.slug], false)
        this.currentViewDataService.selectGame(game.game, true)
    }

    protected readonly UserGameOwnership = UserGameOwnership;
    protected readonly getUserGameOwnershipData = getUserGameOwnershipData;
}

export interface GridGame {
    game: BoardGameBasicResponse,
    ownership?: UserGameOwnership,
    bottomLabel?: string,
    bottomRightLabel?: string,
    topRightNumber?: number,

    isExpansion?: boolean,
    expands?: string[],

    expansions?: GridGame[]
    showExpansions?: boolean,
    displayAsExpansion?: boolean,

    tags?: string[],
}
