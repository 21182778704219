export enum UserGameOwnership {
    OWNED = "OWNED",
    PREVIOUSLY_OWNED = "PREVIOUSLY_OWNED",
    PREORDERED = "PREORDERED",
    WISHLISTED = "WISHLISTED"
}

export type UserGameOwnershipData = {
    id?: string
    name: string
    verb: string
    bannerVerb: string
    transitionTo?: UserGameOwnership[]
    icon: string
}

export const userGameOwnerships: (UserGameOwnership | undefined)[] = [
    UserGameOwnership.OWNED,
    UserGameOwnership.PREVIOUSLY_OWNED,
    UserGameOwnership.PREORDERED,
    UserGameOwnership.WISHLISTED,
    undefined
]

export const userGameOwnershipData: UserGameOwnershipData[] = [
    {
        id: UserGameOwnership.OWNED,
        name: $localize`In Collection`,
        verb: $localize`Add to Collection`,
        bannerVerb: $localize`added to personal collection`,
        transitionTo: [UserGameOwnership.PREVIOUSLY_OWNED],
        icon: 'book'
    },
    {
        id: UserGameOwnership.PREVIOUSLY_OWNED,
        name: $localize`Previously Owned`,
        verb: $localize`Set as Previously Owned`,
        bannerVerb: $localize`no longer owned`,
        transitionTo: [UserGameOwnership.OWNED, UserGameOwnership.PREORDERED, UserGameOwnership.WISHLISTED],
        icon: 'blur_on'
    },
    {
        id: UserGameOwnership.PREORDERED,
        name: $localize`Preordered`,
        verb: $localize`Set as Preordered`,
        bannerVerb: $localize`preordered`,
        transitionTo: [UserGameOwnership.OWNED],
        icon: 'shopping_cart'
    },
    {
        id: UserGameOwnership.WISHLISTED,
        name: $localize`Wishlisted`,
        verb: $localize`Wishlist`,
        bannerVerb: $localize`added to wishlist`,
        transitionTo: [UserGameOwnership.OWNED, UserGameOwnership.PREORDERED],
        icon: 'favorite'
    },
    {
        id: undefined,
        name: $localize`Only Played`,
        verb: '',
        bannerVerb: '',
        icon: 'play_circle'
    }
]

export function getUserGameOwnershipData(id?: UserGameOwnership): UserGameOwnershipData {
    return userGameOwnershipData.find(ownership => ownership.id == id) || userGameOwnershipData[0]
}
