import {booleanAttribute, ChangeDetectorRef, Component, HostListener, Input, NgZone, OnInit} from '@angular/core';
import {Router, RouterLink} from "@angular/router";
import {AuthedUserService} from "../service/authed-user.service";
import {UserBasicInfo} from "../model/user";
import {DrawerService} from "../service/ui/drawer.service";
import {NavigationService} from "../service/ui/navigation.service";
import {BreakpointObserver} from "@angular/cdk/layout";
import {GamePlayResponse, GamePlayState, UserPersonalResponse, UserResponse} from "../model/responses";
import {GamePlayService} from "../service/api/game-play.service";
import {EventService} from "../service/api/event.service";
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {NgIf} from '@angular/common';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatDialog} from "@angular/material/dialog";
import {NotificationService} from "../service/api/notification.service";
import {LoginDialogComponent} from "./login-dialog.component";
import {DialogService} from "../service/ui/dialog.service";
import {LandingService} from "../service/api/landing.service";
import {SharedDialogsService} from "../service/shared-dialogs.service";

@Component({
    selector: 'cb-header',
    template: `
        <mat-toolbar [class.floating]="floating" [class.header-toolbar]="!floating"
                     [class.sticky]="!floating && (backLink || user && !isBigScreen || title)">
            <mat-toolbar-row *ngIf="shouldDisplayPendingPlays()"
                             class="row space-between align-center pending-plays-banner" style="padding: 0 32px"
                             (click)="goToPlay(pendingPlays[0])">
                <span i18n>Play in progress</span>
                <div class="image-container">
                    <img [src]="pendingPlays[0].game.image.thumbnailUrl" alt="game image" class="game-image">
                </div>
            </mat-toolbar-row>
            <mat-toolbar-row class="row space-between align-center">
                <button mat-icon-button *ngIf="!backLink && user && !isBigScreen" (click)="drawerService.open()">
                    <mat-icon>menu</mat-icon>
                </button>
                <button mat-icon-button *ngIf="!backLink && !user && navigationService.currentPath.length >= 2"
                        (click)="navigationService.navigate(['/'])">
                    <mat-icon>home</mat-icon>
                </button>
                <!-- Element with fixed width to align title and menu -->
                <div *ngIf="!backLink && ((user && isBigScreen) || (!user && navigationService.currentPath.length < 2))"
                     style="width: 46px;"></div>

                <button *ngIf="backLink" mat-icon-button (click)="goBack()">
                    <mat-icon>arrow_back</mat-icon>
                </button>

                <div class="row center align-center">
                    <img *ngIf="addLogo" src="/assets/small_logo.png" alt="logo" style="height: 24px; margin-right: 4px">
                    <span class="section-title">{{ title }}</span>
                </div>

                <div *ngIf="user" class="row" style="min-width: 46px">
                    <div *ngIf="showExtraButtons" class="row align-center">
                        <!--<button mat-icon-button routerLink="/search" color="primary" i18n>
                            <mat-icon>search</mat-icon>
                        </button>-->
                        <button mat-icon-button routerLink="/notifications" color="primary">
                            <mat-icon>notifications</mat-icon>
                            <div class="unread-notification-count row center align-center" *ngIf="unreadNotificationCount > 0">
                                <span>{{ unreadNotificationCount }}</span>
                            </div>
                        </button>
                    </div>
                    <ng-content></ng-content>
                </div>

                <div *ngIf="!user">
                    <button mat-icon-button (click)="login()" color="primary">
                        <mat-icon>login</mat-icon>
                    </button>
                </div>
            </mat-toolbar-row>
        </mat-toolbar>
    `,
    styles: [`
        .header-toolbar {
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        }

        .mat-toolbar-single-row {
            padding: 0 6px !important;
        }

        .sticky {
            position: sticky;
            top: 0;
            z-index: 1000;
        }

        .floating {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 1000;
            background-color: transparent !important;
        }

        .avatar-container {
            padding: 6px;
            overflow: hidden;
        }

        .avatar {
            cursor: pointer;
            width: 30px;
            height: 30px;
            object-fit: cover;
            border-radius: 50%
        }

        .section-title {
            margin: 0 4px;
            font-size: 1.6rem;
            font-weight: 700;
        }

        .pending-plays-banner {
            //background-color: rgba(223, 154, 87, 0.2);
            background-color: #374d62;
            border-color: var(--accent-color);
            border-style: solid;
            border-width: 2px;
            //color: #000;
            margin: 4px 4px 0;
            width: calc(100% - 8px);
            border-radius: 8px;

            .image-container {
                width: 40px;
                height: 40px;
                overflow: hidden;
                box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
                border-radius: 4px;
                cursor: pointer;

                .game-image {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        .unread-notification-count {
            position: absolute;
            top: 5px;
            left: 50%;
            background-color: var(--accent-color);
            border-radius: 50%;
            width: 16px;
            height: 16px;
            font-size: 10px;
            letter-spacing: 0.5px;
            font-weight: 500;
            color: #fff;
        }
    `],
    standalone: true,
    imports: [MatToolbarModule, NgIf, MatButtonModule, MatIconModule, RouterLink]
})
export class HeaderComponent implements OnInit {

    @Input()
    title: string = ''

    @Input({transform: booleanAttribute})
    addLogo: boolean = false

    @Input({transform: booleanAttribute})
    floating: boolean = false

    @Input()
    backLink?: string

    @Input({transform: booleanAttribute})
    isBackLinkRoot = false

    @Input({transform: booleanAttribute})
    showExtraButtons = false

    user?: UserPersonalResponse

    isBigScreen = false

    pendingPlays: GamePlayResponse[] = []

    unreadNotificationCount: number = 0

    constructor(
        private authedUserService: AuthedUserService,
        private gamePlayService: GamePlayService,
        private eventService: EventService,
        private router: Router,
        public drawerService: DrawerService,
        public navigationService: NavigationService,
        private notificationService: NotificationService,
        private landingService: LandingService,
        private breakpointObserver: BreakpointObserver,
        private changeDetectorRef: ChangeDetectorRef,
        private dialog: MatDialog,
        private dialogService: DialogService,
        private sharedDialogsService: SharedDialogsService,
        private zone: NgZone,
    ) {
    }

    ngOnInit() {
        console.log(`HeaderComponent ngOnInit`, this.title, this.backLink, this.isBackLinkRoot, this.showExtraButtons, this.addLogo)
        this.user = this.authedUserService.getUserData()
        this.authedUserService.getAuthedUserObservable().subscribe(user => {
            this.user = user?.userData
        })
        this.router.events.subscribe(() => {
            this.user = this.authedUserService.getUserData()
        })

        this.breakpointObserver.observe('(min-width: 1000px)').subscribe(result => {
            this.isBigScreen = result.matches
        })

        if (this.user) {
            this.refreshPendingPlays()
            this.eventService.notificationSubject.subscribe(notification => {
                if (notification.data.type == 'ADDED_TO_PLAY') {
                    this.refreshPendingPlays()
                }
            })
            this.eventService.subscribe('PLAY_UPDATE')
            this.eventService.playUpdateSubject.subscribe(play => {
                if (play.state == GamePlayState.FINISHED) {
                    this.refreshPendingPlays()
                }
            })
            this.notificationService.getUnreadNotificationCount().subscribe(count => {
                this.unreadNotificationCount = count
                this.changeDetectorRef.detectChanges()
            })

            //this.conversationService.getUnreadMessageCount().subscribe(count => {
            //    this.unreadMessageCount = count
            //    this.changeDetectorRef.detectChanges()
            //})

            if (this.dialog.openDialogs.length == 0) {
                this.landingService.getNewsCached().subscribe(response => {
                    if (response.news.totalNumber > 0) {
                        const lastCheckedString = localStorage.getItem('lastCheckedNews')
                        const lastChecked = lastCheckedString ? new Date(lastCheckedString) : new Date(0)
                        const firstLogin = this.user?.registeredSince ? new Date(this.user.registeredSince) : new Date(0)
                        const latestNews = response.news.elements[0]
                        const createdAt = new Date(latestNews.createdAt)
                        if (createdAt > lastChecked && createdAt > firstLogin) {
                            this.sharedDialogsService.openNewsDialog().subscribe(() => {
                                localStorage.setItem('lastCheckedNews', new Date().toISOString())
                            })
                        }
                    }
                })
            }
        }

        (window as any).navigateBackCallback = (): string => {
            if (this.dialog.openDialogs.length > 0) {
                console.log('closing dialog')
                this.zone.run(() => {
                    this.dialog.openDialogs[this.dialog.openDialogs.length - 1].close()
                })
                return 'closed dialog'
            } else if (this.drawerService.drawerState.value) {
                console.log('closing drawer')
                this.zone.run(() => {
                    this.drawerService.close()
                })
                return 'closed drawer'
            } else {
                this.zone.run(() => {
                    this.goBack()
                })
                return 'went back'
            }
        }
        (window as any).canGoBackCallback = (): boolean => {
            return this.dialog.openDialogs.length > 0 || this.canGoBack()
        }
        (window as any).resetNavHistoryCallback = () => {
            this.navigationService.resetHistory()
            this.navigationService.saveHistory()
        }
    }

    ngOnDestroy() {
        //this.eventService.notificationSubject.unsubscribe()
        //this.eventService.playUpdateSubject.unsubscribe()
        //this.eventService.unsubscribe('PLAY_UPDATE')
    }

    refreshPendingPlays() {
        this.gamePlayService.getUserGamePlays(this.user!.username, [GamePlayState.SCORING, GamePlayState.PREPARING, GamePlayState.PLAYING], 1).subscribe(plays => {
            this.pendingPlays = plays.elements
        })
    }

    canGoBack() {
        if (this.backLink) {
            //console.log('we have a back link: ' + this.backLink)
            return true
        }
        //console.log('can go back: ' + this.navigationService.canGoBack())
        return this.navigationService.canGoBack()
    }

    goBack(): string {
        console.log('going back')
        if (this.backLink) {
            this.navigationService.navigateBack([this.backLink], this.isBackLinkRoot)
            return 'back link ' + this.backLink
        } else {
            return this.navigationService.goBack()
        }
    }

    shouldDisplayPendingPlays(): boolean {
        return this.pendingPlays.length > 0 && this.pendingPlays.some(p =>
            this.navigationService.currentPath.indexOf(`/p/${p.id}`) == -1
        )
    }

    goToPlay(play: GamePlayResponse) {
        this.navigationService.navigate(['p', play.id])
    }

    login() {
        const dialogConfig = {
            disableClose: true
        }
        this.dialogService.fullScreenIfMobile(dialogConfig)
        const dialog = this.dialog.open(LoginDialogComponent, dialogConfig)
        this.dialogService.onDialogOpened(dialog)
        dialog.componentInstance.loggedIn.subscribe(() => {
            dialog.close()
            window.location.reload()
        })
    }

    @HostListener('window:popstate', ['$event'])
    onPopState(event: any) {
        this.navigationService.onBackNavigation()
        console.log('browser back: ' + event)
    }
}
