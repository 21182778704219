import {Component, Inject} from "@angular/core";
import {UserPersonalResponse, UserPlayerResponse} from "../../model/responses";
import {AuthedUserService} from "../../service/authed-user.service";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatOptionModule} from '@angular/material/core';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatIconModule} from '@angular/material/icon';
import {CommonModule} from '@angular/common';
import {MatChipsModule} from '@angular/material/chips';
import {MatFormFieldModule} from '@angular/material/form-field';
import {GamePlayService} from "../../service/api/game-play.service";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {MatListModule} from "@angular/material/list";
import {
    MAT_DIALOG_DATA, MatDialog,
    MatDialogActions,
    MatDialogContent,
    MatDialogRef,
    MatDialogTitle
} from "@angular/material/dialog";
import {ShareService} from "../../service/ui/share.service";
import {ImageUploadComponent} from "../../shared/image-upload.component";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {MatSlideToggle} from "@angular/material/slide-toggle";
import {AvatarComponent} from "../avatar.component";
import {LoggedPlayPlayer} from "../../play/players-selection-form.component";
import {UserPlayerTagDialogComponent} from "./user-player-tag-dialog.component";
import {DialogService} from "../../service/ui/dialog.service";
import {UserPlayerMergeDialogComponent} from "./user-player-merge-dialog.component";
import {PlayStatsResponse} from "../../model/statsResponses";
import {datedPeriod, Period} from "../../model/period";
import {InputDialogComponent} from "../../shared/input-dialog.component";
import {randomHexString} from "../../model/utils";

@Component({
    template: `
        <div class="row space-between" style="font-size: 1.3rem; color: white; margin: 24px">
            <span>{{ userPlayer?.name || player?.name }}</span>
            <span *ngIf="userPlayerPlays" style="font-size: 1.1rem; color: #b1b8be"
                  i18n>({{ userPlayerPlays.totalPlayCount }} plays)</span>
        </div>
        <mat-dialog-content>
            <div *ngIf="userPlayer && !saving" class="edit-profile-form column">
                <div class="row center align-center" style="margin-bottom: 16px">
                    <button mat-raised-button color="primary" style="width: 100%" (click)="editName()">
                        <mat-icon>signature</mat-icon>
                        <span i18n>Edit Name</span>
                    </button>
                </div>
                <div class="row center">
                    <cb-avatar size="100" style="margin-bottom: 16px" [isBot]="isBot"
                               [name]="userPlayer.name" [avatarUrl]="userPlayer.avatarUrl"
                               editable (avatarChange)="selectAvatar($event)"
                    ></cb-avatar>
                </div>
                <div class="row space-between align-center">
                    <div class="column align-start">
                        <span class="label" i18n>Is this a bot?</span>
                        <!--<span class="note" i18n>An AI, automa... not a human</span>-->
                    </div>
                    <mat-slide-toggle [(ngModel)]="isBot" (change)="save()" i18n></mat-slide-toggle>
                </div>
                <!--<div class="row space-between align-center">
                    <div class="column align-start">
                        <span class="label" i18n>Make anonymous</span>
                        <span class="note" i18n>Only you can see this player</span>
                    </div>
                    <mat-slide-toggle [(ngModel)]="anonymous" (change)="save()" i18n></mat-slide-toggle>
                </div>-->
                <div class="row center align-center" style="margin-top: 32px" *ngIf="!isBot">
                    <button mat-raised-button color="primary" style="width: 100%" (click)="tag()">
                        <mat-icon>person</mat-icon>
                        <span i18n>Tag as User</span>
                    </button>
                </div>
                <div class="row center align-center" style="margin-top: 16px" *ngIf="!isBot">
                    <button mat-raised-button color="primary" style="width: 100%" (click)="merge()">
                        <mat-icon>call_merge</mat-icon>
                        <span i18n>Merge...</span>
                    </button>
                </div>
            </div>
            <div class="edit-profile-form column center align-center" *ngIf="saving">
                <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
            </div>
        </mat-dialog-content>
        <div mat-dialog-actions class="row space-between" style="margin-top: 16px">
            <button mat-button cdkFocusInitial (click)="cancel()" i18n>Close</button>
        </div>
    `,
    styles: [`
        .label {
            font-size: 1.1rem;
            color: white;
        }
        .note {
            font-size: 0.8rem;
            color: #b1b8be;
        }

        .edit-profile-form {
        }

        .mat-mdc-dialog-content {
            max-height: unset !important;
        }
    `],
    standalone: true,
    imports: [
        CommonModule, ReactiveFormsModule, FormsModule,
        MatFormFieldModule, MatInputModule, MatChipsModule, MatIconModule, MatAutocompleteModule, MatOptionModule, MatButtonModule, MatListModule, MatDialogContent, MatDialogTitle, MatDialogActions, ImageUploadComponent, MatProgressSpinner, MatSlideToggle, AvatarComponent
    ]
})
export class EditUserPlayerDialogComponent {

    currentUser: UserPersonalResponse

    player?: LoggedPlayPlayer
    userPlayer?: UserPlayerResponse
    userPlayerPlays?: PlayStatsResponse

    avatarFile?: Blob
    isBot = false
    anonymous = false

    inviteCode?: string
    saving = false

    constructor(
        private authedUserService: AuthedUserService,
        private dialog: MatDialog,
        private dialogService: DialogService,
        private gamePlayService: GamePlayService,
        @Inject(MAT_DIALOG_DATA) public data: {id?: string, player?: LoggedPlayPlayer},
        private dialogRef: MatDialogRef<EditUserPlayerDialogComponent>,
    ) {
        this.authedUserService.assertAuthedUser()
        this.currentUser = this.authedUserService.getUserData()!
        this.player = data.player
        this.isBot = this.player?.isBot || false
        this.gamePlayService.getUserPlayer(this.data.id || this.player?.userPlayerId!).subscribe(userPlayer => {
            this.userPlayer = userPlayer
            this.isBot = userPlayer.isBot
            this.anonymous = userPlayer.anonymous

            this.gamePlayService.getUserGamePlayPeriodStats(
                userPlayer.creator.username,
                datedPeriod(Period.ALL_TIME),
                userPlayer.id
            ).subscribe({
                next: response => {
                    this.userPlayerPlays = response.playStats
                }
            })
        })
    }

    selectAvatar(file: Blob) {
        this.avatarFile = file
        this.save()
    }

    editName() {
        if (!this.userPlayer) {
            return
        }

        this.dialog.open(InputDialogComponent, {
            data: {
                title: $localize`Edit ${this.userPlayer.name}'s Name`,
                inputLabel: `Player Name`
            }
        }).afterClosed().subscribe(name => {
            if (name) {
                this.userPlayer!.name = name
                this.save()
            }
        })
    }

    tag() {
        if (!this.userPlayer) {
            return
        }
        if (!this.inviteCode) {
            this.inviteCode = randomHexString(8)
            this.save(this.inviteCode)
        }

        const dialogConfig = {
            data: {userPlayer: this.userPlayer, inviteCode: this.inviteCode},
            minWidth: '300px',
            maxWidth: '600px',
        }
        const dialogRef = this.dialog.open(UserPlayerTagDialogComponent, dialogConfig)
        this.dialogService.onDialogOpened(dialogRef)
    }

    merge() {
        const dialogConfig = {
            data: {userPlayer: this.userPlayer, userPlayerPlays: this.userPlayerPlays},
            minWidth: '300px',
            maxWidth: '600px',
        }
        const dialogRef = this.dialog.open(UserPlayerMergeDialogComponent, dialogConfig)
        this.dialogService.onDialogOpened(dialogRef)
        dialogRef.afterClosed().subscribe(merged => {
            if (merged) {
                this.userPlayer = merged
                this.dialogRef.close(merged)
            }
        })
    }

    save(inviteCode?: string) {
        if (!this.userPlayer) {
            return
        }
        this.saving = true
        const request = {
            name: this.userPlayer.name,
            isBot: this.isBot,
            anonymous: this.anonymous,
            inviteCode: inviteCode
        }
        this.gamePlayService.updateUserPlayer(this.userPlayer.id, request, this.avatarFile).subscribe(response => {
            this.userPlayer!.name = response.name
            this.userPlayer!.isBot = response.isBot
            this.userPlayer!.anonymous = response.anonymous
            this.userPlayer!.avatarUrl = response.avatarUrl
            if (this.player) {
                this.player.name = response.name
                this.player.avatarUrl = response.avatarUrl
                this.player.isBot = response.isBot
            }
            this.saving = false
        })
    }

    cancel() {
        this.dialogRef.close(this.userPlayer)
    }
}
