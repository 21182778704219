import {Subscription} from "rxjs";
import {BoardGameBasicResponse} from "../model/responses";
import {SearchService} from "../service/api/search.service";

export class GameSearch {
    preloadedGames: BoardGameBasicResponse[] = [];
    loadedGames: BoardGameBasicResponse[] = [];
    filteredGames: BoardGameBasicResponse[] = [];

    constructor(
        private searchService: SearchService
    ) {
    }

    protected currentFilter = '';
    private lastFilter = ' ';
    private subscription?: Subscription;

    loadGames(filter: string, onlyBaseGames: boolean = false) {
        //console.log(filter, onlyBaseGames)
        if (filter == this.lastFilter) {
            return
        }
        this.currentFilter = filter
        if (this.subscription?.closed === false) {
            // Wait for the previous request to finish
            setTimeout(() => {
                // Only perform the request if the filter hasn't changed
                if (this.currentFilter == filter) {
                    this.performFilter(filter, onlyBaseGames)
                }
            }, 100)
            return
        }
        this.lastFilter = filter
        this.subscription = this.searchService.searchGames(filter, onlyBaseGames).subscribe(response => {
            this.loadedGames = response.list
            this.performFilter(filter, onlyBaseGames)
        });
    }

    performFilter(filter: string, onlyBaseGames: boolean = false) {
        //console.log(filter, onlyBaseGames)
        this.currentFilter = filter
        const filteredPreloadedGames = this.preloadedGames.filter(game => game.name.toLowerCase().includes(filter.toLowerCase()))
        //console.log(filteredPreloadedGames)
        if (filteredPreloadedGames.length >= 20) {
            this.filteredGames = filteredPreloadedGames
            return
        }
        this.loadGames(filter, onlyBaseGames)
        this.filteredGames = filteredPreloadedGames.concat(this.loadedGames.filter(game => !this.preloadedGames.find(g => g.id === game.id)))
    }
}
