import {Inject, Injectable, LOCALE_ID} from "@angular/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {AuthedUserService} from "../authed-user.service";

@Injectable()
export class ShareService {

    constructor(
        private snackBar: MatSnackBar,
        private httpClient: HttpClient,
        @Inject(LOCALE_ID) private locale: string,
    ) {
    }

    shareLink(title: string, text: string, uri: string) {
        let langParam = ''
        if (this.locale !== 'en') {
            const char = uri.includes('?') ? '&' : '?'
            langParam = `${char}lang=${this.locale}`
        }
        const url = `https://app.ludoya.com/${uri}${langParam}`
        if ((window as any).Android && (window as any).Android.share) {
            (window as any).Android.share(title, text, url)
        } else if (navigator.share) {
            navigator.share({
                title: title,
                text: text,
                url: url
            }).catch(console.error)
        } else {
            // Copy link
            navigator.clipboard.writeText(url).then(() => {
                this.snackBar.open($localize`Link copied to clipboard`, undefined, {duration: 2000})
            })
        }
        this.httpClient.get(`${environment.apiUrl}/site-meta/${uri}`, {responseType: 'text'}).subscribe()
    }

    shareImage(title: string, url: string) {
        if ((window as any).Android && (window as any).Android.shareImage) {
            (window as any).Android.shareImage(title, url)
        } else if (navigator.share) {
            fetch(url)
                .then(response => response.blob())
                .then(blob => {
                    // convert blob to file
                    const fileName = url.substring(url.lastIndexOf('/') + 1);
                    const file = new File([blob], fileName, {type: blob.type});
                    navigator.share({
                        title: title,
                        files: [file]
                    }).catch(console.error)
                });
        } else {
            // Copy link
            navigator.clipboard.writeText(url).then(() => {
                this.snackBar.open($localize`Link copied to clipboard`, undefined, {duration: 2000})
            })
        }
    }
}
