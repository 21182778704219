import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges,} from "@angular/core";
import {AuthedUserService} from "../service/authed-user.service";
import {FormControl, ReactiveFormsModule, FormsModule} from "@angular/forms";
import {LoggedPlayPlayer} from './players-selection-form.component';
import {MatDividerModule} from "@angular/material/divider";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {CommonModule} from "@angular/common";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatInputModule} from "@angular/material/input";
import {MatFormFieldModule} from "@angular/material/form-field";
import {parseDurationToSeconds} from "./edit-duration-dialog.component";
import {BoardGameBasicResponse, GamePlayTemplateResponse, LocationResponse} from "../model/responses";
import {AvatarComponent} from "../user/avatar.component";
import {SharedDialogsService} from "../service/shared-dialogs.service";
import {Visibility} from "../model/visibility";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {PlayScoreSheetDialogComponent} from "./play-score-sheet-dialog.component";
import {DialogService} from "../service/ui/dialog.service";

@Component({
    selector: 'cb-log-play-data-form',
    template: `
        <form class="log-play-form column">
            <mat-form-field class="log-full-width" appearance="fill">
                <mat-label i18n>Date</mat-label>
                <input matInput
                       [matDatepicker]="picker"
                       [formControl]="dateControl"
                       [max]="currentDate"
                       (dateChange)="updateData()"
                       readonly
                       (click)="picker.open()"
                       (focus)="picker.open()">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <div class="row center align-center" style="margin-bottom: 8px">
                <button mat-stroked-button style="width: 100%" *ngIf="playData?.location" (click)="openLocationsDialog()">
                    <mat-icon>location_on</mat-icon>
                    <span>{{ playData?.location?.name }}</span>
                </button>
                <button mat-icon-button class="small-icon-button" *ngIf="playData?.location" (click)="clearLocation()">
                    <mat-icon>close</mat-icon>
                </button>
                <button mat-stroked-button style="width: 100%" *ngIf="!playData?.location" (click)="openLocationsDialog()">
                    <mat-icon>not_listed_location</mat-icon>
                    <span i18n>Set Play Location</span>
                </button>
            </div>
            <div class="row space-between align-center" style="width: 100%">
                <span class="form-heading" i18n>Players</span>
                <div class="row">
                    <button *ngIf="displayedPlayers.length > 1" mat-icon-button color="primary" (click)="shufflePlayers()">
                        <mat-icon>shuffle</mat-icon>
                    </button>
                    <button mat-icon-button color="primary" (click)="openScoreSheet()">
                        <!--<span class="material-symbols-outlined">rubric</span>-->
                        <mat-icon>table_view</mat-icon>
                    </button>
                </div>
            </div>
            <mat-accordion>
                <mat-expansion-panel *ngFor="let player of displayedPlayers" expanded>
                    <mat-expansion-panel-header>
                        <div class="row space-between align-center fillWidth">
                            <div class="row left align-center">
                                <div class="winner-indicator column center align-center" *ngIf="someWinner()">
                                    <mat-icon *ngIf="player.isWinner">emoji_events</mat-icon>
                                    <mat-icon *ngIf="!player.isWinner">remove</mat-icon>
                                </div>
                                <cb-avatar [name]="player.name" [avatarUrl]="player.avatarUrl" [size]="34"></cb-avatar>
                                <div class="player-text"
                                     [style.max-width]="'calc(100vw - ' + (64 + 48 + 48 + 32 + (player.score ? 30 : 0)) + 'px)'">
                                    <span class="team-name" *ngIf="player.players" i18n>[TEAM]</span>
                                    <span class="player-name">{{ player.name }}</span>
                                    <span class="player-username" *ngIf="player.username">&#64;{{ player.username }}</span>
                                    <!--<span class="player-username" *ngIf="!player.username" i18n>(Non-user player)</span>-->
                                    <!--<span class="player-username" *ngIf="!player.username" i18n>(Non-user player)</span>-->
                                </div>
                            </div>
                            <span class="player-score">{{ player.score }}</span>
                        </div>
                    </mat-expansion-panel-header>
                    <div class="row space-between align-center" style="width: 100%">
                        <mat-form-field style="padding-right: 20px" subscriptSizing="dynamic">
                            <mat-label i18n>Score</mat-label>
                            <input matInput type="text" [(ngModel)]="player.score" [ngModelOptions]="{standalone: true}"
                                   (ngModelChange)="updateData()">
                        </mat-form-field>
                        <mat-slide-toggle [checked]="player.isWinner" (change)="setWinner(player, $event.checked)"
                                          i18n>Winner</mat-slide-toggle>
                    </div>
                    <div class="row end align-center" style="width: 100%" *ngIf="!player.players">
                        <mat-form-field subscriptSizing="dynamic">
                            <mat-label i18n>Role</mat-label>
                            <input matInput type="text" [(ngModel)]="player.color" [ngModelOptions]="{standalone: true}"
                                   (ngModelChange)="updateData()">
                        </mat-form-field>
                    </div>
                    <div class="row space-between align-center" style="width: 100%; margin-bottom: 16px"
                         *ngIf="player.userPlayerId">
                        <button mat-raised-button color="primary" style="width: 100%" (click)="editUserPlayer(player)"
                                i18n>Edit</button>
                    </div>
                    <mat-accordion *ngIf="player.players">
                        <mat-expansion-panel *ngFor="let teamPlayer of player.players">
                            <mat-expansion-panel-header>
                                <div class="row space-between align-center fillWidth">
                                    <div class="row left align-center">
                                        <cb-avatar [name]="teamPlayer.name" [avatarUrl]="teamPlayer.avatarUrl" [size]="34"></cb-avatar>
                                        <div class="player-text"
                                             [style.max-width]="'calc(100vw - ' + (64 + 48 + 48 + 32 + 32) + 'px)'">
                                            <span class="player-name">{{ teamPlayer.name }}</span>
                                            <span class="player-username" *ngIf="teamPlayer.username">&#64;{{ teamPlayer.username }}</span>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel-header>
                            <div class="row end align-center" style="width: 100%">
                                <mat-form-field subscriptSizing="dynamic">
                                    <mat-label i18n>Role</mat-label>
                                    <input matInput type="text" [(ngModel)]="teamPlayer.color" [ngModelOptions]="{standalone: true}"
                                           (ngModelChange)="updateData()">
                                </mat-form-field>
                            </div>
                            <div class="row space-between align-center" style="width: 100%; margin-bottom: 16px"
                                 *ngIf="teamPlayer.userPlayerId">
                                <button mat-raised-button color="primary" style="width: 100%" (click)="editUserPlayer(teamPlayer)"
                                        i18n>Edit</button>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </mat-expansion-panel>
            </mat-accordion>
            <button mat-raised-button color="primary" class="select-players-button" (click)="selectPlayers()">
                <mat-icon>group</mat-icon>
                <span i18n>Select players</span>
            </button>
            <mat-divider class="section-separation"></mat-divider>
            <mat-expansion-panel style="margin-bottom: 16px">
                <mat-expansion-panel-header>
                    <div class="row start align-center" style="width: 100%">
                        <mat-icon>loupe</mat-icon>
                        <span class="panel-heading" i18n>Extra details</span>
                        <span class="optional-heading" i18n>(optional)</span>
                    </div>
                </mat-expansion-panel-header>
                <div class="row" style="width: 100%">
                    <mat-form-field class="flex fill" appearance="fill" (change)="onDurationChange()">
                        <mat-label *ngIf="playData?.count == 1" i18n>Duration</mat-label>
                        <mat-label *ngIf="playData?.count != 1" i18n>Total duration</mat-label>
                        <input matInput type="text" [formControl]="durationControl" placeholder="1h 30m">
                        <mat-error *ngIf="durationControl.hasError('invalidDuration')" i18n>
                            Please enter a valid duration (e.g. 1h 30m)
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field style="max-width: 100px; margin-right: 16px" appearance="fill" (change)="onCountChange()">
                        <mat-label i18n>Times played</mat-label>
                        <input matInput type="number" [formControl]="countControl">
                        <mat-error *ngIf="countControl.hasError('invalidCount')" i18n>
                            Please enter a valid play count
                        </mat-error>
                    </mat-form-field>
                </div>
                <mat-form-field appearance="fill" style="width: 100%" (change)="updateData()">
                    <mat-label i18n>Personal notes</mat-label>
                    <textarea matInput [formControl]="notesControl" rows="3" i18n-placeholder placeholder="It was fun! Next time I want to..."></textarea>
                </mat-form-field>
            </mat-expansion-panel>
        </form>
    `,
    styles: [`
        .section-separation {
            margin-bottom: 16px;
        }

        .log-play-form {

            .mat-form-field {
                width: 100%;
            }

            .log-full-width {
                width: 100%;
            }

            .add-player-field {
                margin-top: 10px;
            }
        }

        .form-heading {
            margin-top: 16px;
            margin-bottom: 8px;
            font-size: 1.1rem;
            font-weight: 700;
        }

        .panel-heading {
            margin-left: 8px;
            font-size: 1rem;
            font-weight: 400;
        }

        .optional-heading {
            margin-left: 8px;
            font-size: 1rem;
            font-weight: 400;
            color: #b1b8be;
        }

        .winner-indicator {
            width: 30px;
        }

        .player-text {
            margin-left: 8px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            .player-name {
                font-size: 1rem;
                font-weight: 700;
            }

            .player-username {
                margin-left: 6px;
                font-size: 1rem;
                font-weight: 400;
                color: #b1b8be;
                min-width: 70px;
            }

            .team-name {
                margin-right: 6px;
                font-size: 0.938rem;
                font-weight: 400;
                color: #b1b8be;
                min-width: 70px;
            }
        }

        .player-score {
            font-size: 1rem;
            font-weight: 700;
            text-decoration: underline;
            text-align: right;
            margin-right: 8px;
        }

        .select-players-button {
            margin-top: 8px;
        }
    `],
    standalone: true,
    imports: [
        CommonModule, ReactiveFormsModule, FormsModule,
        MatFormFieldModule, MatInputModule, MatDatepickerModule, MatExpansionModule, MatIconModule, MatButtonModule, MatSlideToggleModule, MatDividerModule, AvatarComponent
    ]
})
export class LogPlayDataFormComponent implements OnInit {

    dateControl = new FormControl(new Date());
    countControl = new FormControl('1');
    durationControl = new FormControl('');
    notesControl = new FormControl('');

    @Input()
    game?: BoardGameBasicResponse
    @Input()
    players: LoggedPlayPlayer[] = []

    displayedPlayers: LoggedPlayPlayer[] = []
    currentDate = new Date()

    @Input()
    playData?: GamePlayData
    @Output()
    playDataChange = new EventEmitter<GamePlayData>()

    selectedTemplate?: GamePlayTemplateResponse

    constructor(
        private authedUserService: AuthedUserService,
        private sharedDialogsService: SharedDialogsService,
        private dialogService: DialogService,
        private dialog: MatDialog
    ) {
    }

    ngOnInit() {
        this.authedUserService.assertAuthedUser()
        if (this.playData) {
            this.dateControl.setValue(this.playData.date || new Date())
            this.countControl.setValue((this.playData.count || 1).toString())
            this.durationControl.setValue(this.playData.duration || null)
            this.notesControl.setValue(this.playData.notes || null)
            this.updateDisplayedPlayers()
            if (!this.playData.date) {
                this.updateData()
            }
        } else {
            this.updateDisplayedPlayers()
            this.updateData()
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        this.updateDisplayedPlayers()
        if (changes['game'] && changes['game'].previousValue != changes['game'].currentValue) {
            this.selectedTemplate = undefined
            if (this.playData) {
                this.playData.template = undefined
                this.playDataChange.emit(this.playData)
            }
        }
    }

    updateDisplayedPlayers() {
        const teamNames = this.players.filter(p => p.team)
            .map(p => p.team!)
            .filter((v, i, a) => a.indexOf(v) === i)
        const teams: LoggedPlayPlayer[] = teamNames.map(t => {
            const players = this.players.filter(p => p.team == t)
            const player = players[0]
            return {
                name: t,
                isWinner: player.isWinner,
                score: player.score,
                scoreBreakdown: player.scoreBreakdown,
                players: players,
            }
        })
        this.displayedPlayers = this.players.filter(p => !p.team)
        this.displayedPlayers.push(...teams)
    }

    updateTeamPlayers() {
        for (let team of this.displayedPlayers.filter(p => p.players)) {
            for (let teamPlayer of team.players!) {
                teamPlayer.isWinner = team.isWinner
                teamPlayer.score = team.score
                teamPlayer.scoreBreakdown = team.scoreBreakdown
            }
        }
    }

    someWinner(): boolean {
        return this.displayedPlayers.some(p => p.isWinner)
    }

    setWinner(player: LoggedPlayPlayer, value: boolean) {
        player.isWinner = value
        this.updateData()
    }

    onDurationChange() {
        if (!this.durationControl.value) {
            this.durationControl.setErrors(null)
        }

        if (this.durationControl.value) {
            if (!parseDurationToSeconds(this.durationControl.value)) {
                this.durationControl.setErrors({invalidDuration: true})
            } else {
                this.durationControl.setErrors(null)
            }
        }
        this.updateData()
    }

    onCountChange() {
        if (!this.countControl.value) {
            this.countControl.setErrors(null)
        }

        if (this.countControl.value) {
            if (!parseInt(this.countControl.value) || parseInt(this.countControl.value) < 1) {
                this.countControl.setErrors({invalidCount: true})
            } else {
                this.countControl.setErrors(null)
            }
        }
        this.updateData()
    }

    updateData() {
        this.updateTeamPlayers()
        const date = this.dateControl.value!!
        const duration = this.durationControl.value || undefined
        const durationMinutes = duration ? parseDurationToSeconds(duration) : undefined
        if (this.durationControl.value && !durationMinutes) {
            this.durationControl.setErrors({invalidDuration: true})
            return
        }
        const count = this.countControl.value ? parseInt(this.countControl.value) : undefined
        if (this.countControl.value && (!count || count < 1)) {
            this.countControl.setErrors({invalidCount: true})
            return
        }
        const notes = this.notesControl.value
        this.playData = {
            ...this.playData,
            players: this.players,
            date: date,
            count: count,
            duration: duration,
            notes: notes && notes.length > 0 ? notes : undefined,
            template: this.selectedTemplate || this.playData?.template
        }
        this.playDataChange.emit(this.playData)
    }

    shufflePlayers() {
        this.sharedDialogsService.shufflePlayers(this.displayedPlayers).subscribe(players => {
            if (players) {
                this.displayedPlayers = players
                this.updateData()
            }
        })
    }

    openScoreSheet() {
        let dialogConfig: MatDialogConfig = {
            data: {
                playId: this.playData?.playId,
                game: this.game,
                data: this.playData
            }
        }
        this.dialogService.fullScreenIfMobile(dialogConfig)
        const dialog = this.dialog.open(PlayScoreSheetDialogComponent, dialogConfig)
        this.dialogService.onDialogOpened(dialog)
        return dialog.afterClosed().subscribe(() => {
            if (this.playData?.template != this.selectedTemplate) {
                this.selectedTemplate = this.playData?.template
                this.updateData()
            }
        })
    }

    selectPlayers() {
        this.sharedDialogsService.selectPlayers(this.players).subscribe(players => {
            if (players) {
                this.players = players
                this.updateDisplayedPlayers()
                this.updateData()
            }
        })
    }

    openLocationsDialog() {
        this.sharedDialogsService.openLocations(true, Visibility.PRIVATE).subscribe(location => {
            if (location) {
                this.playData!.location = location
                this.updateData()
            }
        })
    }

    clearLocation() {
        this.playData!.location = undefined
        this.updateData()
    }

    editUserPlayer(player: LoggedPlayPlayer) {
        if (!player.userPlayerId) {
            return
        }
        this.sharedDialogsService.editUserPlayer(player).subscribe(player => {
            if (player) {
                this.players = this.players.map(p =>
                    p.userPlayerId == player.id ? {...p, ...player} : p
                )
                this.updateDisplayedPlayers()
                this.updateData()
            }
        })
    }
}

export type GamePlayData = {
    playId?: string
    players: LoggedPlayPlayer[]
    date: Date
    expansions?: BoardGameBasicResponse[]
    count?: number
    duration?: string
    location?: LocationResponse
    notes?: string
    template?: GamePlayTemplateResponse
}
