import {Injectable} from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import {map, Observable, tap} from "rxjs";
import {
    UserGameAddRequest,
    UserGameSuggestionRejectRequest,
    UserGameUpdateRequest
} from "../../model/requests";
import {
    UserGamesResponse,
    BoardGameResponse, BoardGameNamesResponse, BoardGameListResponse, UserGameResponse
} from "../../model/responses";
import {environment} from "../../../environments/environment";
import {UserGameOwnership} from "../../model/user-game-ownership";
import {AuthedUserService} from "../authed-user.service";

@Injectable()
export class UserGameService {

    constructor(
        private http: HttpClient,
        private authedUserService: AuthedUserService,
    ) {
    }

    LOCAL_STORAGE_KEY = 'currentUserGames';
    LOCAL_STORAGE_TIME_KEY = 'currentUserGamesTime';

    getAllCurrentUserGames(): Observable<UserGameResponse[]> {
        const username = this.authedUserService.getAuthedUser()?.userData?.username;
        if (!username) {
            return new Observable<UserGameResponse[]>(subscriber => {
                subscriber.next([]);
                subscriber.complete();
            });
        }
        const allStoredGames = localStorage.getItem(this.LOCAL_STORAGE_KEY);
        const allStoredGamesTime = localStorage.getItem(this.LOCAL_STORAGE_TIME_KEY);
        if (allStoredGames && allStoredGamesTime && Date.now() - parseInt(allStoredGamesTime) < 1000 * 3600) {
            return new Observable<UserGameResponse[]>(subscriber => {
                subscriber.next(JSON.parse(allStoredGames));
                subscriber.complete();
            });
        } else {
            return this.getUserGames(username).pipe(
                map(response => response.games),
                tap({
                    next: games => {
                        localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(games));
                        localStorage.setItem(this.LOCAL_STORAGE_TIME_KEY, Date.now().toString());
                    }
                })
            );
        }
    }

    evictCachedGames() {
        localStorage.removeItem(this.LOCAL_STORAGE_KEY);
        localStorage.removeItem(this.LOCAL_STORAGE_TIME_KEY);
    }

    getGame(slug: string): Observable<BoardGameResponse> {
        return this.http.get<BoardGameResponse>(`${environment.apiUrl}/users/me/boardgames/${slug}`);
    }

    addGame(id: string, request: UserGameAddRequest, attachments: Blob[]) {
        const formData = new FormData();
        formData.append('request', JSON.stringify(request));
        for (let i = 0; i < attachments.length; i++) {
            formData.append(`attachment-${i}`, attachments[i]);
        }
        this.evictCachedGames();
        return this.http.post<any>(`${environment.apiUrl}/users/me/boardgames/${id}`, formData);
    }

    updateGame(id: string, request: UserGameUpdateRequest) {
        this.evictCachedGames();
        return this.http.put<any>(`${environment.apiUrl}/users/me/boardgames/${id}`, request);
    }

    updateGameTags(id: string, tags: string[]) {
        const request = {tags: tags};
        this.evictCachedGames();
        return this.http.put<any>(`${environment.apiUrl}/users/me/boardgames/${id}/tags`, request);
    }

    getUserGames(
        username: string,
        ownership?: UserGameOwnership,
        notOwnedPlayed?: boolean,
        tag?: string
    ): Observable<UserGamesResponse> {
        let params = new HttpParams();
        if (ownership) {
            params = params.append('ownership', ownership.toString())
        }
        if (notOwnedPlayed) {
            params = params.append('notOwnedPlayed', notOwnedPlayed.toString())
        }
        if (tag) {
            params = params.append('tag', tag)
        }
        return this.http.get<UserGamesResponse>(
            `${environment.apiUrl}/users/${username}/boardgames`,
            {params: params}
        );
    }

    getLastPlayedGames(): Observable<BoardGameNamesResponse> {
        return this.http.get<BoardGameNamesResponse>(`${environment.apiUrl}/users/me/last-played`);
    }

    suggestGames(
        playerCount: number,
        collectionUserIds: string[],
        preferredDurationMinutes?: number,
        preferredWeight?: number
    ): Observable<BoardGameListResponse> {
        let params = new HttpParams();
        params = params.append('playerCount', playerCount.toString())
        params = params.append('collectionUserIds', collectionUserIds.join(','))
        if (preferredDurationMinutes) {
            params = params.append('preferredDurationMinutes', preferredDurationMinutes.toString())
        }
        if (preferredWeight) {
            params = params.append('preferredWeight', preferredWeight.toString())
        }
        return this.http.get<BoardGameListResponse>(
            `${environment.apiUrl}/users/me/boardgames/suggest`,
            {params: params}
        );
    }

    rejectGameSuggestion(id: string, forever: boolean) {
        const request: UserGameSuggestionRejectRequest = {
            gameId: id,
            forever: forever
        }
        return this.http.post<any>(`${environment.apiUrl}/users/me/boardgames/reject`, request);
    }
}
