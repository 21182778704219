import {Injectable} from '@angular/core';
import {BehaviorSubject, catchError, map, Observable, of, ReplaySubject, tap} from 'rxjs';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";

@Injectable()
export class GoogleMapsService {
    private apiLoadedSubject: ReplaySubject<boolean> = new ReplaySubject(1);
    private isLoadApiCalled = false;

    constructor(private httpClient: HttpClient) {
    }

    public getApiLoadStatus(): Observable<boolean> {
        if (!this.isLoadApiCalled) {
            this.isLoadApiCalled = true;
            this.httpClient.jsonp(`https://maps.googleapis.com/maps/api/js?key=${environment.mapsApiKey}`, 'callback')
                .pipe(
                    map(() => true),
                    catchError(() => of(false)),
                    tap(loaded => {
                        if (loaded) {
                            this.apiLoadedSubject.next(true);
                            this.apiLoadedSubject.complete();
                        } else {
                            this.apiLoadedSubject.error('Failed to load Google Maps API');
                        }
                    }),
                )
                .subscribe();
        }
        return this.apiLoadedSubject.asObservable();
    }
}
