import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'playDate',
    standalone: true
})
export class PlayDatePipe implements PipeTransform {

    transform(input: Date | string): string {
        return getPlayDate(input) || ''
    }
}

export function getPlayDate(input: Date | string | undefined): string | undefined {
    if (!input) {
        return undefined;
    }
    let date = new Date(input);
    let now = new Date();
    let dateDay = date.getTime() / (1000 * 60 * 60 * 24);
    let nowDay = now.getTime() / (1000 * 60 * 60 * 24);

    let diffInDays = nowDay - dateDay;
    if (diffInDays < 7) {
        if (diffInDays >= 1) {
            return Math.floor(diffInDays) + 'd';
        } else {
            return $localize`today`;
        }
    } else {
        if (date.getFullYear() !== now.getFullYear()) {
            // Format: 'Dec 21, 2020'
            return date.toLocaleString('default', {month: 'short', day: 'numeric', year: 'numeric'});
        } else {
            // Format: 'Feb 14'
            return date.toLocaleString('default', {month: 'short', day: 'numeric'});
        }
    }
}
