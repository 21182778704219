import {Component, EventEmitter, Output} from '@angular/core';
import {readAndCompressImage} from "browser-image-resizer";

@Component({
    selector: 'cb-image-upload',
    template: `
      <div (click)="fileInput.click()">
        <ng-content></ng-content>
      </div>
      <input #fileInput hidden type="file" id="file-input" accept="image/jpeg, image/png" (change)="selectFile($event)">
  `,
    styles: [`
  `],
    standalone: true
})
export class ImageUploadComponent {
  file?: Blob

  @Output()
  onFileSelected = new EventEmitter<Blob>();

  selectFile(event: Event) {
    const file = (event.target as HTMLInputElement)!.files![0];
    compressImage(file).then(compressedFile => {
      this.file = compressedFile;
      this.onFileSelected.emit(this.file);
    });
  }
}

export function preview(file: Blob, targetElementId: string) {
  var reader = new FileReader();
  reader.onload = function(e) {
    // get loaded data and render thumbnail.
    document.getElementById(targetElementId)!.setAttribute('src', e.target!.result as string);
  };
  // read the image file as a data URL.
  reader.readAsDataURL(file);
}

export function previewByUrl(url: string, targetElementId: string) {
  document.getElementById(targetElementId)?.setAttribute('src', url);
}

export function removePreview(targetElementId: string) {
  document.getElementById(targetElementId)?.setAttribute('src', '');
}

const compressImageConfig = {
  quality: 0.75,
  maxWidth: 3200,
  maxHeight: 3200
}

export async function compressImage(file: File): Promise<Blob> {
  return await readAndCompressImage(file, compressImageConfig)
}
