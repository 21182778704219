import {booleanAttribute, Component, EventEmitter, Input, Output, ViewChild} from "@angular/core";
import {NavigationService} from "../service/ui/navigation.service";
import {CommonModule} from "@angular/common";
import {datedPeriod, DatedPeriod, datedPeriodToString, Period, periodData} from "../model/period";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatDatepickerModule, MatDateRangePicker} from "@angular/material/datepicker";

@Component({
    selector: 'cb-period-select',
    template: `
        <div class="column">
            <div class="row center align-center">
                <a class="period-button" [class.big-period-button]="big" *ngFor="let p of periodData"
                   [class.selected]="selectedPeriod.period === p.period" (click)="selectPeriod(p.period)">
                    {{ p.display }}
                </a>
            </div>
            <div class="row center align-center">
                <button class="small-icon-button" mat-icon-button [disabled]="!canGoBack()" (click)="goBack()">
                    <mat-icon>chevron_left</mat-icon>
                </button>
                <div style="max-width: 0; max-height: 0">
                    <mat-date-range-input hidden [rangePicker]="picker">
                        <input hidden matStartDate (dateChange)="startDate = $event.value" placeholder="Start date">
                        <input hidden matEndDate (dateChange)="endDate = $event.value; setCustomRange()" placeholder="End date">
                    </mat-date-range-input>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </div>
                <span (click)="picker.open()" class="period-string" [class.big-period-string]="big">{{datedPeriodToString(selectedPeriod)}}</span>
                <button class="small-icon-button" mat-icon-button [disabled]="!canGoForward()" (click)="goForward()">
                    <mat-icon>chevron_right</mat-icon>
                </button>
            </div>
        </div>
    `,
    styles: [`
        .period-button {
            border-radius: 8px;
            cursor: pointer;
            color: #ffffff;

            font-size: 0.8rem;
            padding: 4px 8px;

            @media (min-width: 600px) {
                font-size: 0.9rem;
                padding: 8px 12px;
            }
        }

        .big-period-button {
            font-size: 0.9rem;
            padding: 8px 12px;
        }

        .period-button.selected {
            color: var(--primary-color);
            font-weight: 700;
        }

        .period-string {
            text-align: center;

            min-width: 150px;
            font-size: 0.9rem;

            cursor: pointer;

            @media (min-width: 600px) {
                min-width: 200px;
                font-size: 1rem;
            }
        }

        .big-period-string {
            min-width: 200px;
            font-size: 1rem;
        }
    `],
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule, MatIconModule, MatDatepickerModule,
    ]
})
export class PeriodSelectComponent {

    @ViewChild('picker')
    picker: MatDateRangePicker<any>

    @Input()
    selectedPeriod: DatedPeriod = datedPeriod(Period.THIRTY_DAYS)
    @Output()
    selectedPeriodChange = new EventEmitter<DatedPeriod>()

    @Input({transform: booleanAttribute})
    big: boolean = true
    @Input({transform: booleanAttribute})
    includeAllTime: boolean = true //false

    startDate?: Date
    endDate?: Date

    get periodData() {
        let pd = [...periodData]
        if (!this.includeAllTime) {
            pd = pd.filter(p => p.period != Period.ALL_TIME)
        }
        if (!this.big) {
            pd = pd.filter(p => p.period != Period.CUSTOM)
        }
        return pd
    }

    constructor(
        public navigationService: NavigationService,
    ) {
    }

    ngOnInit() {
        if (!this.includeAllTime && this.selectedPeriod.period === Period.ALL_TIME) {
            this.selectPeriod(datedPeriod(Period.THIRTY_DAYS))
        }
    }

    selectPeriod(p: any) {
        if (p == Period.CUSTOM) {
            this.picker.open()
            return
        } else {

        }
        this.selectedPeriod = datedPeriod(p)
        this.selectedPeriodChange.emit(this.selectedPeriod)
    }

    canGoBack() {
        return this.selectedPeriod.period != Period.ALL_TIME
    }

    goBack() {
        this.selectedPeriod = datedPeriod(this.selectedPeriod.period, new Date(), this.selectedPeriod.index + 1)
        this.selectedPeriodChange.emit(this.selectedPeriod)
    }

    canGoForward() {
        return this.selectedPeriod.index > 0
    }

    goForward() {
        this.selectedPeriod = datedPeriod(this.selectedPeriod.period, new Date(), this.selectedPeriod.index - 1)
        this.selectedPeriodChange.emit(this.selectedPeriod)
    }

    setCustomRange() {
        if (!this.startDate || !this.endDate) {
            return

        }
        this.selectedPeriod = datedPeriod(Period.CUSTOM, this.startDate, 0, this.endDate)
        this.selectedPeriodChange.emit(this.selectedPeriod)
    }

    protected readonly datedPeriodToString = datedPeriodToString;
}
