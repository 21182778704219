
export function evaluateExpression(expression?: string): number | undefined {
    if (!expression) {
        return undefined;
    }
    // Remove spaces and handle trailing operator
    expression = expression.replace(/\s/g, '').replace(/[+\-*×x/]$/, '');

    // Handle initial minus (unary minus)
    if (expression.startsWith('-')) {
        expression = '0' + expression;
    }

    // Precedence of operators
    const precedence: { [key: string]: number } = {
        '+': 1,
        '-': 1,
        '*': 2,
        '×': 2,
        'x': 2,
        '/': 2,
        '÷': 2
    };

    // Function to compare precedence of operators
    const hasHigherPrecedence = (op1: string, op2: string): boolean => {
        return precedence[op1] > precedence[op2];
    };

    // Convert infix expression to Reverse Polish Notation (RPN)
    const toRPN = (infix: string): string[] => {
        let outputQueue: string[] = [];
        let operatorStack: string[] = [];
        let tokens = infix.match(/(\d+|[+\-*×x/÷()])/g);

        if (!tokens) {
            return [];
        }

        tokens.forEach(token => {
            if (!isNaN(parseFloat(token))) {
                outputQueue.push(token);
            } else if ('+-*x/÷'.includes(token)) {
                while (operatorStack.length && hasHigherPrecedence(operatorStack[operatorStack.length - 1], token)) {
                    outputQueue.push(operatorStack.pop()!);
                }
                operatorStack.push(token);
            } else if (token === '(') {
                operatorStack.push(token);
            } else if (token === ')') {
                while (operatorStack.length && operatorStack[operatorStack.length - 1] !== '(') {
                    outputQueue.push(operatorStack.pop()!);
                }
                operatorStack.pop(); // Pop the '('
            }
        });

        while (operatorStack.length) {
            outputQueue.push(operatorStack.pop()!);
        }

        return outputQueue;
    };

    // Evaluate RPN expression
    const evaluateRPN = (rpn: string[]): number => {
        let stack: number[] = [];

        rpn.forEach(token => {
            if (!isNaN(parseFloat(token))) {
                stack.push(parseFloat(token));
            } else {
                let b = stack.pop()!;
                let a = stack.pop()!;
                switch (token) {
                    case '+':
                        stack.push(a + b); break;
                    case '-':
                        stack.push(a - b); break;
                    case '*':
                    case 'x':
                    case 'X':
                    case '×':
                        stack.push(a * b); break;
                    case '/':
                    case '÷':
                        stack.push(a / b); break;
                }
            }
        });

        return stack.pop()!;
    };

    try {
        const rpn = toRPN(expression);
        const evaluation = evaluateRPN(rpn);
        // Always return an integer
        return Math.floor(evaluation);
    } catch (error) {
        console.error("Error in evaluating expression:", error);
        return undefined;
    }
}
