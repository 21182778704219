import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from '@angular/material/button';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {CommonModule} from '@angular/common';
import {MatInputModule} from "@angular/material/input";
import {FormsModule} from "@angular/forms";

@Component({
    selector: 'cb-edit-duration-dialog',
    template: `
        <h1 mat-dialog-title i18n>Edit Duration</h1>
        <mat-dialog-content>
            <mat-form-field class="fillWidth" appearance="fill">
                <mat-label i18n>Duration</mat-label>
                <input type="text" matInput name="duration" [(ngModel)]="durationString" (ngModelChange)="updateDurationSeconds()">
            </mat-form-field>
        </mat-dialog-content>
        <div mat-dialog-actions class="row space-between">
            <button mat-button mat-dialog-close i18n>Cancel</button>
            <button mat-raised-button color="primary" (click)="save()"
                    [disabled]="durationSeconds == data.durationSeconds || !durationSeconds"
                    i18n>Save</button>
        </div>
    `,
    styles: [`
        .mat-mdc-dialog-content {
            max-height: unset !important;
        }
    `],
    standalone: true,
    imports: [
        CommonModule, FormsModule,
        MatDialogModule, MatProgressSpinnerModule, MatButtonModule, MatInputModule
    ],
})
export class EditDurationDialogComponent {

    durationString: string
    durationSeconds?: number

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { durationSeconds: number },
        private dialogRef: MatDialogRef<EditDurationDialogComponent>
    ) {
        this.durationSeconds = data.durationSeconds
        this.durationString = durationToString(data.durationSeconds)
    }

    updateDurationSeconds() {
        this.durationSeconds = parseDurationToSeconds(this.durationString)
    }

    save() {
        this.dialogRef.close(this.durationSeconds)
    }
}

export function durationToString(durationSeconds: number): string {
    const h = Math.floor(durationSeconds / 3600)
    const m = Math.floor((durationSeconds % 3600) / 60)
    const s = durationSeconds % 60
    return h > 0 ? `${h}h ${m}m ${s}s` : `${m}m ${s}s`
}

export function durationToShortString(durationSeconds: number): string {
    const h = Math.floor(durationSeconds / 3600)
    const m = Math.floor((durationSeconds % 3600) / 60)
    return h > 0 ? (h >= 10 ? `>10h` : `${h}h ${m}m`) : (m > 0 ? `${m}m` : '<1m')
}

export function shortenDurationString(duration: string): string {
    const durationSeconds = parseDurationToSeconds(duration)
    return durationSeconds ? durationToShortString(durationSeconds) : duration
}

export function durationToCounterString(durationSeconds: number): string {
    const hours = Math.floor(durationSeconds / 3600)
    const minutes = Math.floor((durationSeconds % 3600) / 60)
    const seconds = Math.floor(durationSeconds % 60)

    return hours > 0 ?
        `${hours.toString()}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}` :
        `${minutes.toString()}:${seconds.toString().padStart(2, '0')}`
}

export function parseDurationToSeconds(duration: string): number | undefined {
    const parts = duration.split(' ')
    let totalSeconds = 0
    for (const part of parts) {
        if (part.length == 0) {
            continue
        }
        const letter = part.charAt(part.length - 1)
        const value = parseInt(part.substring(0, part.length - 1))
        if (isNaN(value) || value < 0) {
            return undefined
        }
        if (letter == 'h') {
            totalSeconds += value * 3600
        } else if (letter == 'm') {
            totalSeconds += value * 60
        } else if (letter == 's') {
            totalSeconds += value
        } else {
            return undefined
        }
    }
    return totalSeconds
}
