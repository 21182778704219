import {booleanAttribute, Component, EventEmitter, Input, numberAttribute, Output} from '@angular/core';
import {preview, ImageUploadComponent} from "../shared/image-upload.component";
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {CommonModule} from '@angular/common';

@Component({
    selector: 'cb-image',
    template: `
        <div class="image-container"
             [style.width.px]="width" [style.height.px]="height"
             [style.min-width.px]="width" [style.min-height.px]="height"
             [style.max-width.px]="width" [style.max-height.px]="height"
        >
            <div class="image non-image-image" *ngIf="!imageUrl && !imageFile"></div>
            <img class="image" *ngIf="imageUrl && !imageFile" src="{{imageUrl}}" alt="image">
            <img id="image-preview" class="image" *ngIf="imageFile" alt="image">
            <cb-image-upload *ngIf="editable" (onFileSelected)="selectImage($event)">
                <div class="image-edit-button column center align-center">
                    <mat-icon>add_photo_alternate</mat-icon>
                </div>
            </cb-image-upload>
        </div>
    `,
    styles: [`
        .image-container {
            position: relative;
            border-radius: 4px;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;

            .image {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .non-image-image {
                display: flex;
                width: 100%;
                height: 100%;
                background-color: var(--panel-background-color);
            }

            .image-edit-button {
                position: relative;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background-color: rgba(0, 0, 0, 0.5);
                cursor: pointer;
                z-index: 1;
            }
        }
    `],
    standalone: true,
    imports: [CommonModule, ImageUploadComponent, MatIconModule, MatButtonModule]
})
export class ImageComponent {

    @Input({transform: numberAttribute})
    width: number = 100
    @Input({transform: numberAttribute})
    height: number = 100
    @Input()
    imageUrl?: string

    @Input({transform: booleanAttribute})
    editable = false
    @Output()
    imageChange = new EventEmitter<Blob>()

    imageFile?: Blob

    constructor(
    ) {
    }

    selectImage(file: Blob) {
        this.imageFile = file
        this.imageChange.emit(file)
        preview(file, 'image-preview')
    }
}

