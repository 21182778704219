import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {FormControl, ReactiveFormsModule, FormsModule} from "@angular/forms";
import {BoardGameBasicResponse} from "../model/responses";
import {BoardGameService} from "../service/api/board-game.service";
import {MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {CommonModule} from "@angular/common";

@Component({
    selector: 'cb-game-expansions-form',
    template: `
        <div class="expansions-toggle row end" *ngIf="expansions.length > 0 && !pickExpansions">
            <mat-slide-toggle [(ngModel)]="pickExpansions" i18n>With expansions?</mat-slide-toggle>
        </div>

        <mat-form-field class="fill" appearance="fill" *ngIf="expansions.length > 0 && pickExpansions">
            <mat-label i18n>Expansion(s)</mat-label>
            <mat-select [formControl]="expansionsControl" multiple>
                <mat-option *ngFor="let expansion of expansions" [value]="expansion">
                    <div class="row start align-center">
                        <img width="40" height="40" [src]="expansion.image.thumbnailUrl" [alt]="expansion.name"/>
                        <span style="padding: 5px">{{ expansion.name }} ({{ expansion.yearPublished }})</span>
                    </div>
                </mat-option>
            </mat-select>
        </mat-form-field>
    `,
    styles: [`
        .expansions-toggle {
            margin-bottom: 16px;
        }
    `],
    standalone: true,
    imports: [
        CommonModule, ReactiveFormsModule, FormsModule,
        MatSlideToggleModule, MatFormFieldModule, MatSelectModule, MatOptionModule
    ]
})
export class GameExpansionsFormComponent implements OnInit {

    @Input()
    game: BoardGameBasicResponse

    @Input()
    selectedExpansions: BoardGameBasicResponse[] = []

    @Output()
    selectedExpansionsChange = new EventEmitter<BoardGameBasicResponse[]>()

    pickExpansions = false
    expansionsControl = new FormControl<BoardGameBasicResponse[]>([])

    expansions: BoardGameBasicResponse[] = []

    constructor(
        private boardGamesService: BoardGameService
    ) {
        this.expansionsControl.valueChanges.subscribe(value => {
            this.selectedExpansionsChange.emit(value || [])
        })
    }

    ngOnInit() {
        this.boardGamesService.getGameExpansions(this.game.slug).subscribe(response => {
            this.expansions = response.expansions
            if (this.selectedExpansions.length > 0) {
                this.pickExpansions = true
                this.expansionsControl.setValue(response.expansions.filter(expansion =>
                    this.selectedExpansions.some(selected => selected.slug === expansion.slug)
                ))
            }
        })
    }

    ngOnChanges() {
        //console.log(this.selectedExpansions)
        if (this.selectedExpansions && this.selectedExpansions.length > 0) {
            //this.pickExpansions = true
        }
    }
}
