import {Injectable} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {IdResponse, LocationsResponse} from "../../model/responses";
import {LocationRequest} from "../../model/requests";

@Injectable()
export class LocationService {

  constructor(
    private http: HttpClient,
  ) {
  }

  getLocations(): Observable<LocationsResponse> {
    return this.http.get<LocationsResponse>(`${environment.apiUrl}/locations`);
  }

  createLocation(location: LocationRequest): Observable<IdResponse> {
    return this.http.post<IdResponse>(`${environment.apiUrl}/locations`, location);
  }

  updateLocation(id: string, location: LocationRequest): Observable<any> {
    return this.http.put(`${environment.apiUrl}/locations/${id}`, location);
  }

  deleteLocation(id: string): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/locations/${id}`);
  }
}
