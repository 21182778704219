import {Component, Inject, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
    MAT_DIALOG_DATA,
    MatDialogModule, MatDialogRef,
} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {UserPlayerComponent} from "./user-player.component";
import {UserPlayerResponse} from "../../model/responses";
import {AcceptGamePlayInvitationRequest} from "../../model/requests";
import {GamePlayService} from "../../service/api/game-play.service";
import {MatSlideToggle} from "@angular/material/slide-toggle";
import {FormsModule} from "@angular/forms";

@Component({
    template: `
        <h1 mat-dialog-title i18n>Tag as {{data.userPlayer.name}}</h1>
        <mat-dialog-content>
            <p i18n>Are you sure you want to tag yourself as {{data.userPlayer.name}}?</p>
            <p i18n>Your profile will be linked to all of this player's game plays.</p>
            <p i18n *ngIf="includeInProfile">Your profile will also include all these plays as your own.</p>
            <p i18n *ngIf="!includeInProfile">Your profile will not include these plays.</p>
            <mat-slide-toggle [(ngModel)]="includeInProfile" i18n>Include in profile</mat-slide-toggle>
        </mat-dialog-content>
        <div mat-dialog-actions class="row space-between">
            <button mat-button cdkFocusInitial mat-dialog-close i18n>Cancel</button>
            <button mat-raised-button color="accent" (click)="tagSelf()" i18n>Tag</button>
        </div>
    `,
    styles: [`
        .mat-mdc-dialog-content {
            max-height: unset !important;
        }
    `],
    standalone: true,
    imports: [
        CommonModule,
        MatDialogModule, MatButtonModule,
        UserPlayerComponent, MatSlideToggle, FormsModule,
    ]
})
export class UserPlayerTagSelfDialogComponent {

    includeInProfile: boolean = true

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { userPlayer: UserPlayerResponse, inviteCode: string },
        private dialogRef: MatDialogRef<UserPlayerTagSelfDialogComponent>,
        private gamePlayService: GamePlayService,
    ) {
    }

    tagSelf() {

        const request: AcceptGamePlayInvitationRequest = {
            inviteCode: this.data.inviteCode,
            includeInProfile: this.includeInProfile
        }
        this.gamePlayService.acceptUserPlayerInvitation(this.data.userPlayer.id, request).subscribe({
            next: () => {
                this.dialogRef.close(true)
            }
        });
    }
}
