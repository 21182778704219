import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from '@angular/material/button';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {CommonModule} from '@angular/common';
import {MatInputModule} from "@angular/material/input";
import {FormsModule} from "@angular/forms";
import {AvatarComponent} from "../user/avatar.component";
import {MatChipAvatar} from "@angular/material/chips";
import {MatListOption} from "@angular/material/list";
import {LoggedPlayPlayer} from "./players-selection-form.component";

@Component({
    template: `
        <h1 mat-dialog-title i18n>Random Player Order</h1>
        <mat-dialog-content>
            <div class="column">
                <div *ngFor="let player of resortedPlayers; index as i" class="player-text row left align-center" style="padding: 5px">
                    <span class="player-number" style="margin-right: 4px">#{{ i + 1 }}</span>
                    <cb-avatar matChipAvatar [size]="34" style="min-width: 34px"
                               [name]="player.name" [avatarUrl]="player.avatarUrl" [isBot]="player.isBot">
                    </cb-avatar>
                    <span class="player-name" style="margin-left: 10px">{{ player.name }}</span>
                    <span *ngIf="player.username" class="player-username">&#64;{{ player.username }}</span>
                </div>
            </div>
        </mat-dialog-content>
        <div mat-dialog-actions class="row space-between">
            <button mat-button mat-dialog-close i18n>Cancel</button>
            <button mat-raised-button color="primary" (click)="save()" i18n>Save</button>
        </div>
    `,
    styles: [`
        .player-text {
            margin-left: 8px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            .player-name {
                font-size: 1rem;
                font-weight: 700;
            }

            .player-username {
                margin-left: 6px;
                font-size: 1rem;
                font-weight: 400;
                color: #b1b8be;
                min-width: 70px;
            }
        }

        .mat-mdc-dialog-content {
            max-height: unset !important;
        }
    `],
    standalone: true,
    imports: [
        CommonModule, FormsModule,
        MatDialogModule, MatProgressSpinnerModule, MatButtonModule, MatInputModule, AvatarComponent, MatChipAvatar, MatListOption
    ],
})
export class PlayerOrderDialogComponent {

    resortedPlayers: PlayerDisplay[]

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { players: PlayerDisplay[] },
        private dialogRef: MatDialogRef<PlayerOrderDialogComponent>
    ) {
        this.resortedPlayers = [...data.players];
        // Use the Fisher-Yates (Knuth) shuffle algorithm
        for (let i = this.resortedPlayers.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [this.resortedPlayers[i], this.resortedPlayers[j]] = [this.resortedPlayers[j], this.resortedPlayers[i]];
        }
    }

    save() {
        this.dialogRef.close(this.resortedPlayers)
    }
}

export type PlayerDisplay = {
    name: string
    username?: string
    avatarUrl?: string
    isBot?: boolean
}
