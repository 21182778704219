import {DatePipe} from "@angular/common";
import {dateToString} from "./period";

export type DateDisplay = {
    text: string,
    class: string,
}

export function getPlannedPlayDateDisplay(datePipe: DatePipe, time: string): DateDisplay {
    const startDate = dateToString(time)
    const startTime = datePipe.transform(time, 'shortTime')!
    return getEventDateDisplay(datePipe, startDate, startTime)
}

export function getMeetupDateDisplay(datePipe: DatePipe, startsAt: string, endsAt?: string): DateDisplay {
    const startDate = dateToString(startsAt)
    const startTime = datePipe.transform(startsAt, 'shortTime')!
    const endDate = endsAt ? dateToString(endsAt) : undefined
    const endTime = endsAt ? datePipe.transform(endsAt, 'shortTime')! : undefined
    return getEventDateDisplay(datePipe, startDate, startTime, endDate, endTime)
}

export function getEventDateDisplay(datePipe: DatePipe, eventStartDate: string, eventStartTime: string, eventEndDate?: string, eventEndTime?: string): DateDisplay {
    const date = new Date()
    const meetupStart = getDate(eventStartDate, eventStartTime)
    const meetupEnd = getDate(eventEndDate || eventStartDate, eventEndTime || eventStartTime)

    let meetupStartDate = new Date(eventStartDate)
    meetupStartDate.setHours(0, 0, 0, 0)
    let currentDate = new Date()
    currentDate.setHours(0, 0, 0, 0)
    let clazz = ''
    if (date < meetupStart) {
        let weekDay = datePipe.transform(meetupStart, 'EEEE')!
        weekDay = weekDay.charAt(0).toUpperCase() + weekDay.slice(1)
        let day: string
        if (meetupStartDate.getTime() - currentDate.getTime() == 0) {
            day = $localize`Today`
            clazz = 'today'
        } else if (meetupStartDate.getTime() - currentDate.getTime() == 24 * 60 * 60 * 1000){
            day = $localize`Tomorrow`
            clazz = 'soon'
        } else {
            day = weekDay + ' ' + datePipe.transform(meetupStart, 'mediumDate')
            if (meetupStartDate.getFullYear() == currentDate.getFullYear()) {
                day = day.replace(meetupStartDate.getFullYear() + '', '').trim()
            }
        }
        let time = day + ' ' + eventStartTime
        if (eventEndTime) {
            time += ' - ' + eventEndTime
        }
        return {text: time, class: clazz}
    } else if (date > meetupEnd) {
        return {text: $localize`Finished`, class: 'past'}
    } else {
        return {text: $localize`Currently running`, class: 'now'}
    }
}

export function getDate(dateString: string | Date, timeString: string): Date {
    const date = new Date(dateString)
    const time = timeString.split(':')
    date.setHours(parseInt(time[0]), parseInt(time[1]), 0, 0)
    return date
}

/**
 * Generates a random hexadecimal string of the provided length.
 *
 * @param length - The length of the desired hexadecimal string.
 * @returns A random hexadecimal string.
 */
export function randomHexString(length: number): string {
    const characters = 'abcdef0123456789';
    const charactersArray = new Array(length);

    for (let i = 0; i < length; i++) {
        charactersArray[i] = characters.charAt(Math.floor(Math.random() * characters.length));
    }

    return charactersArray.join('');
}

export function getDurationSeconds(timerStartedAt?: Date, durationSeconds?: number) {
    let counterSeconds = 0
    if (timerStartedAt) {
        const now = new Date().getTime()
        counterSeconds += (now - new Date(timerStartedAt).getTime()) / 1000
    }
    if (durationSeconds) {
        counterSeconds += durationSeconds
    }
    if (counterSeconds < 0) {
        counterSeconds = 0
    }
    return Math.round(counterSeconds)
}
