
export type UserToken = {
  username: string
}

export type UserBasicInfo = {
  id: string
  username: string
  name: string
  avatarUrl?: string
}

export enum UserGamePlayAccess {
  VIEW = "VIEW",
  EDIT_SELF = "EDIT_SELF",
  EDIT = "EDIT"
}

export enum UserSearchIntent {
  MESSAGE = "MESSAGE",
  PLAY = "PLAY"
}
