import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";
import { LoginComponent } from './login.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'cb-login-dialog',
    template: `
    <div class="close-button">
      <button mat-icon-button tabindex="-1" (click)="closeDialog()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <cb-login (loggedIn)="loggedIn.emit($event)"></cb-login>
  `,
    styles: [`
    .close-button {
    }
  `],
    standalone: true,
    imports: [MatButtonModule, MatIconModule, LoginComponent]
})
export class LoginDialogComponent implements OnInit {

  @Output()
  loggedIn = new EventEmitter<boolean>();

  constructor(
    private dialogRef: MatDialogRef<LoginDialogComponent>,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.router.events.subscribe(() => {
      this.closeDialog()
    })
  }

  closeDialog() {
    this.dialogRef.close()
  }
}
