import {Component, EventEmitter, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'cb-confirm-dialog',
    template: `
    <h1 mat-dialog-title>{{data.title}}</h1>
    <div mat-dialog-content>
        {{data.question}}
    </div>
    <div mat-dialog-actions class="row space-around">
      <button mat-button mat-dialog-close i18n>Cancel</button>
      <button mat-raised-button color="primary" mat-dialog-close
              (click)="dialogRef.close(true)"
              i18n>Confirm</button>
    </div>
  `,
    styles: [`
  `],
    standalone: true,
    imports: [MatDialogModule, MatButtonModule]
})
export class ConfirmDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {title: string, question: string},
    public dialogRef: MatDialogRef<ConfirmDialogComponent>
  ) {
  }
}
