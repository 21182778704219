import {Injectable} from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import {Observable} from "rxjs";
import {
  ChangePasswordRequest,
  GoogleLoginRequest,
  LoginRequest,
  RecoverPasswordRequest,
  RegisterRequest,
  ResetPasswordRequest,
  SetPasswordRequest,
  UpdateUserGeoLocationRequest,
  UsernameUpdateRequest,
  UserPrivacySettingsRequest,
  UserUpdateRequest,
  VerifyEmailRequest
} from "../../model/requests";
import {
  BoardGameNamesResponse, SearchResponse, UsersBasicResponse,
} from "../../model/responses";
import {environment} from "../../../environments/environment";
import {UserSearchIntent} from "../../model/user";

@Injectable()
export class SearchService {

  constructor(
    private http: HttpClient
  ) {
  }

  search(query: string): Observable<SearchResponse> {
    let params = new HttpParams()
      .set('query', query);
    return this.http.get<SearchResponse>(`${environment.apiUrl}/search`, {params: params});
  }

  searchUsers(query: string, intent?: UserSearchIntent): Observable<UsersBasicResponse> {
    let params = new HttpParams()
      .set('query', query);
    if (intent) {
      params = params.append('intent', intent);
    }
    return this.http.get<UsersBasicResponse>(`${environment.apiUrl}/search/users`, {params: params});
  }

  searchGames(name: string, onlyBase: boolean = false): Observable<BoardGameNamesResponse> {
    let params = new HttpParams()
      .set('query', name)
      .set('onlyBase', onlyBase);
    return this.http.get<BoardGameNamesResponse>(`${environment.apiUrl}/search/boardgames`, {params: params});
  }
}
