import {Injectable} from "@angular/core";
import {MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {BreakpointObserver} from "@angular/cdk/layout";
import {CurrentViewDataService} from "../current-view-data.service";

@Injectable()
export class DialogService {

    constructor(
        private dialog: MatDialog,
        private currentViewDataService: CurrentViewDataService,
        private breakpointObserver: BreakpointObserver
    ) {
    }

    public fullScreenIfMobile(matDialogConfig: MatDialogConfig) {
        if (this.breakpointObserver.isMatched('(max-width: 650px)')) {
            matDialogConfig.minWidth = '100%'
            matDialogConfig.width = '100%'
            matDialogConfig.maxWidth = '100%'
            matDialogConfig.height = '100%'
            matDialogConfig.maxHeight = '100%'
        }
    }

    public bigIfMobile(matDialogConfig: MatDialogConfig) {
        if (this.breakpointObserver.isMatched('(max-width: 650px)')) {
            matDialogConfig.minWidth = '95%'
            matDialogConfig.width = '95%'
            matDialogConfig.maxWidth = '100%'
            //matDialogConfig.height = '80%'
            matDialogConfig.maxHeight = '100%'
        }
    }

    public onDialogOpened(dialog: MatDialogRef<any>) {
        console.log('onDialogOpened')
        if ((window as any).Android && (window as any).Android.setInDialog) {
            (window as any).Android.setInDialog(true)
        }
        dialog.afterClosed().subscribe(_ => {
            if (this.currentViewDataService.selectedDrawerGame || this.dialog.openDialogs.length > 0) {
                return
            }
            console.log('onDialogClosed')
            if ((window as any).Android && (window as any).Android.setInDialog) {
                (window as any).Android.setInDialog(false)
            }
        })
    }

    public onMenuOpened() {
        console.log('onMenuOpened')
        if ((window as any).Android && (window as any).Android.setInDialog) {
            (window as any).Android.setInDialog(true)
        }
    }

    public onMenuClosed() {
        if (this.currentViewDataService.selectedDrawerGame || this.dialog.openDialogs.length > 0) {
            return
        }
        console.log('onMenuClosed')
        if ((window as any).Android && (window as any).Android.setInDialog) {
            (window as any).Android.setInDialog(false)
        }
    }
}
