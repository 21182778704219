import {Component, Inject} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef,} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {UserPlayerComponent} from "./user-player.component";
import {UserPlayerBasicResponse, UserPlayerResponse} from "../../model/responses";
import {GamePlayService} from "../../service/api/game-play.service";
import {AvatarComponent} from "../avatar.component";
import {MatChipAvatar, MatChipGrid, MatChipInput, MatChipRemove, MatChipRow} from "@angular/material/chips";
import {MatFormField, MatFormFieldModule, MatLabel} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatListOption, MatSelectionList} from "@angular/material/list";
import {
    MatAutocomplete,
    MatAutocompleteModule,
    MatAutocompleteTrigger,
    MatOption
} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {MatInput, MatInputModule} from "@angular/material/input";
import {MatOptionModule} from "@angular/material/core";
import {ConfirmDialogComponent} from "../../shared/confirm-dialog.component";
import {datedPeriod, Period} from "../../model/period";
import {PlayStatsResponse} from "../../model/statsResponses";

@Component({
    template: `
        <h1 mat-dialog-title i18n>Merge {{ data.userPlayer.name }} with another player</h1>
        <mat-dialog-content>
            <div class="column align-center" *ngIf="!otherPlayer">
                <span style="margin-bottom: 8px" i18n>Select the player to merge with {{ data.userPlayer.name }}</span>
                <mat-form-field appearance="fill">
                    <mat-label i18n>Other Player</mat-label>
                    <input type="text" matInput
                           [formControl]="playerControl"
                           (input)="onInputType($event)"
                           [matAutocomplete]="player"
                    />
                    <mat-autocomplete #player="matAutocomplete">
                        <mat-option *ngFor="let player of filteredPlayers" [value]="player.name"
                                    (onSelectionChange)="selectPlayer(player, $event)">
                            <div class="row start align-center">
                                <cb-avatar [name]="player.name" [avatarUrl]="player.avatarUrl" [size]="34"></cb-avatar>
                                <span style="padding: 5px">{{ player.name }}</span>
                            </div>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div class="column align-center" *ngIf="otherPlayer">
                <span i18n>All the data and game plays of {{ otherPlayer.name }}
                    will be merged into {{ data.userPlayer.name }}'s</span>
                <div class="row start align-center" style="margin-top: 8px">
                    <cb-avatar [name]="otherPlayer.name" [avatarUrl]="otherPlayer.avatarUrl" [size]="34"></cb-avatar>
                    <span style="padding: 8px; color: white; font-size: 1.3rem">{{ otherPlayer.name }}</span>
                    <span i18n>({{ otherPlayerPlays?.totalPlayCount }} plays)</span>
                </div>
                <mat-icon style="margin: 4px">arrow_downward</mat-icon>
                <div class="row start align-center">
                    <cb-avatar [name]="data.userPlayer.name" [avatarUrl]="data.userPlayer.avatarUrl"
                               [size]="34"></cb-avatar>
                    <span style="padding: 8px; color: white; font-size: 1.3rem">{{ data.userPlayer.name }}</span>
                    <span i18n>({{ data.userPlayerPlays?.totalPlayCount }} plays)</span>
                </div>
            </div>
        </mat-dialog-content>
        <div mat-dialog-actions class="row space-between">
            <button mat-button cdkFocusInitial mat-dialog-close i18n>Cancel</button>
            <button mat-raised-button color="primary" [disabled]="!otherPlayer" (click)="merge()" i18n>Merge</button>
        </div>
    `,
    styles: [`
        .mat-mdc-dialog-content {
            max-height: unset !important;
        }
    `],
    standalone: true,
    imports: [
        CommonModule,
        MatDialogModule, MatButtonModule, MatFormFieldModule, MatInputModule, MatAutocompleteModule, MatOptionModule,
        UserPlayerComponent, AvatarComponent, MatChipAvatar, MatChipGrid, MatChipInput, MatChipRemove, MatChipRow, MatFormField, MatIcon, MatLabel, MatListOption, MatOption, MatSelect, MatSelectionList, ReactiveFormsModule, MatAutocomplete, MatAutocompleteTrigger, MatInput,
    ]
})
export class UserPlayerMergeDialogComponent {

    playerControl = new FormControl('');

    otherPlayers: UserPlayerBasicResponse[] = []
    filteredPlayers: UserPlayerBasicResponse[] = []

    otherPlayer?: UserPlayerBasicResponse

    otherPlayerPlays?: PlayStatsResponse

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { userPlayer: UserPlayerResponse, userPlayerPlays?: PlayStatsResponse },
        private matDialogRef: MatDialogRef<UserPlayerMergeDialogComponent>,
        private dialog: MatDialog,
        private gamePlayService: GamePlayService,
    ) {
    }

    ngOnInit() {
        this.gamePlayService.getUserPlayers().subscribe({
            next: (response) => {
                this.otherPlayers = response.list
                    .filter(player => player.id !== this.data.userPlayer.id)
                this.filteredPlayers = this.otherPlayers
            }
        })
    }

    onInputType(event: any) {
        this.performFilter(event.target.value)
    }

    performFilter(filter: string) {
        this.filteredPlayers = this.otherPlayers
            .filter(player => player.name.toLowerCase().includes(filter.toLowerCase()))
    }

    selectPlayer(player: UserPlayerBasicResponse, event: any) {
        if (event.source.selected) {
            this.otherPlayer = player

            this.gamePlayService.getUserGamePlayPeriodStats(
                this.data.userPlayer.creator.username,
                datedPeriod(Period.ALL_TIME),
                player.id
            ).subscribe({
                next: response => {
                    this.otherPlayerPlays = response.playStats
                }
            })
        }
    }

    merge() {
        if (!this.otherPlayer) {
            return
        }
        this.dialog.open(ConfirmDialogComponent, {
            data: {
                title: $localize`Merge Players`,
                question: $localize`Are you sure you want to permanently merge ${this.otherPlayer.name}'s data into ${this.data.userPlayer.name}'s?`,
            }
        }).afterClosed().subscribe(confirmed => {
            if (confirmed) {
                this.gamePlayService.mergeUserPlayers(this.data.userPlayer.id, this.otherPlayer!.id).subscribe({
                    next: () => {
                        this.matDialogRef.close(this.data.userPlayer)
                    }
                })
            }
        })
    }
}
