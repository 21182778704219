import {Injectable} from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import {Observable} from "rxjs";
import {
    GamePlayBasicResponse,
    GamePlayPeriodStatsResponse,
    GamePlayResponse,
    GamePlayState,
    Page, UserPlayerListResponse, UserPlayerBasicResponse, UserPlayerResponse
} from "../../model/responses";
import {environment} from "../../../environments/environment";
import {
    AcceptGamePlayInvitationRequest,
    CreateUserPlayerRequest,
    EditGamePlayUserRequest,
    GamePlayRequest,
    GamePlayUpdateRequest, UpdateUserPlayerRequest
} from "../../model/requests";
import {UserGamePlayAccess} from "../../model/user";
import {DatedPeriod, periodToRequest} from "../../model/period";

@Injectable()
export class GamePlayService {

    constructor(
        private http: HttpClient,
    ) {
    }

    getGamePlay(id: string): Observable<GamePlayResponse> {
        return this.http.get<GamePlayResponse>(`${environment.apiUrl}/gameplays/${id}`);
    }

    getUserGamePlays(username: string, states: GamePlayState[] = [GamePlayState.FINISHED], count: number = 100): Observable<Page<GamePlayResponse>> {
        const params = {states: states.join(','), pagination: count + ',' + 0}
        return this.http.get<Page<GamePlayResponse>>(`${environment.apiUrl}/users/${username}/gameplays`, {params: params})
    }

    getUserGamePlaysPeriod(username: string, period: DatedPeriod, userPlayerId?: string, otherUserId?: string): Observable<Page<GamePlayResponse>> {
        let params = new HttpParams().appendAll({
            states: GamePlayState.FINISHED,
            period: periodToRequest(period),
        })
        if (userPlayerId) {
            params = params.append('userPlayerId', userPlayerId)
        }
        if (otherUserId) {
            params = params.append('otherUserId', otherUserId)
        }
        return this.http.get<Page<GamePlayResponse>>(`${environment.apiUrl}/users/${username}/gameplays`, {params: params})
    }

    getUserGamePlayPeriodStats(username: string, period: DatedPeriod, userPlayerId?: string, otherUserId?: string): Observable<GamePlayPeriodStatsResponse> {
        let params = new HttpParams().appendAll({
            period: periodToRequest(period),
        })
        if (userPlayerId) {
            params = params.append('userPlayerId', userPlayerId)
        }
        if (otherUserId) {
            params = params.append('otherUserId', otherUserId)
        }
        return this.http.get<GamePlayPeriodStatsResponse>(`${environment.apiUrl}/users/${username}/gameplays/stats`, {params: params})
    }

    getGameGamePlays(gameId: string, includeFriends: boolean = false): Observable<Page<GamePlayBasicResponse>> {
        const params = {includeFriends: includeFriends}
        return this.http.get<Page<GamePlayBasicResponse>>(`${environment.apiUrl}/gameplays/game/${gameId}`, {params: params});
    }

    createGamePlay(request: GamePlayRequest, attachments: Blob[]): Observable<GamePlayResponse> {
        const formData = new FormData();
        formData.append('request', JSON.stringify(request));
        for (let i = 0; i < attachments.length; i++) {
            formData.append(`attachment-${i}`, attachments[i]);
        }
        return this.http.post<GamePlayResponse>(`${environment.apiUrl}/gameplays`, formData);
    }

    updateGamePlay(playId: string, request: GamePlayUpdateRequest): Observable<any> {
        return this.http.patch<any>(`${environment.apiUrl}/gameplays/${playId}`, request);
    }

    updateGamePlayerScore(playId: string, playerId: string, score?: number, scoreBreakdown?: {}): Observable<any> {
        return this.http.put<any>(
            `${environment.apiUrl}/gameplays/${playId}/players/${playerId}/score`,
            {score: score, scoreBreakdown: scoreBreakdown}
        );
    }

    updateGamePlayerWinner(playId: string, playerId: string, isWinner?: boolean): Observable<any> {
        return this.http.put<any>(
            `${environment.apiUrl}/gameplays/${playId}/players/${playerId}/winner`,
            {isWinner: isWinner}
        );
    }

    deleteGamePlay(playId: string): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/gameplays/${playId}`);
    }

    addGamePlayUser(playId: string, userId: string, access: UserGamePlayAccess): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/gameplays/${playId}/users`, {userId: userId, access: access});
    }

    setGamePlayUser(playId: string, userId: string, request: EditGamePlayUserRequest): Observable<any> {
        return this.http.put<any>(`${environment.apiUrl}/gameplays/${playId}/users/${userId}`, request);
    }

    removeGamePlayUser(playId: string, userId: string): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/gameplays/${playId}/users/${userId}`);
    }

    setGamePlayUserAccess(playId: string, userId: string, access: UserGamePlayAccess): Observable<any> {
        return this.http.put<any>(`${environment.apiUrl}/gameplays/${playId}/users/${userId}`, {access: access});
    }

    getUserPlayers(includePlayCount: boolean = false): Observable<UserPlayerListResponse> {
        let params = new HttpParams().append('includePlayCount', includePlayCount.toString());
        return this.http.get<UserPlayerListResponse>(`${environment.apiUrl}/user-players`, {params: params});
    }

    getUserPlayer(id: string): Observable<UserPlayerResponse> {
        return this.http.get<UserPlayerResponse>(`${environment.apiUrl}/user-players/${id}`);
    }

    createUserPlayer(request: CreateUserPlayerRequest): Observable<UserPlayerBasicResponse> {
        return this.http.post<UserPlayerBasicResponse>(`${environment.apiUrl}/user-players`, request);
    }

    updateUserPlayer(id: string, request: UpdateUserPlayerRequest, avatar?: Blob): Observable<UserPlayerResponse> {
        const formData = new FormData();
        formData.append('request', JSON.stringify(request));
        if (avatar) {
            formData.append(`avatar`, avatar);
        }
        return this.http.put<UserPlayerResponse>(`${environment.apiUrl}/user-players/${id}`, formData);
    }

    deleteUserPlayer(id: string): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/user-players/${id}`);
    }

    mergeUserPlayers(id: string, otherId: string): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/user-players/${id}/merge`, {otherUserPlayerId: otherId});
    }

    acceptUserPlayerInvitation(id: string, request: AcceptGamePlayInvitationRequest): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/user-players/${id}/invitation`, request);
    }
}
