import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable, tap} from "rxjs";
import {NewsResponse, RoadmapResponse} from "../../model/responses";
import {environment} from "../../../environments/environment";

@Injectable()
export class LandingService {

    constructor(
        private http: HttpClient,
    ) {
    }

    getRoadmap(): Observable<RoadmapResponse> {
        return this.http.get<RoadmapResponse>(`${environment.apiUrl}/landing/roadmap`)
    }

    voteRoadmapItem(featureId: string): Observable<any> {
        return this.http.post(`${environment.apiUrl}/landing/roadmap/${featureId}/vote`, {})
    }

    getNews(): Observable<NewsResponse> {
        return this.http.get<NewsResponse>(`${environment.apiUrl}/landing/news`)
    }

    cachedNews?: NewsResponse

    getNewsCached(): Observable<NewsResponse> {
        if (this.cachedNews) {
            return new Observable(subscriber => {
                subscriber.next(this.cachedNews!)
                subscriber.complete()
            })
        }
        return this.getNews().pipe(
            tap(news => {
                this.cachedNews = news
            })
        )
    }
}

