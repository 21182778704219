

function hashCode(str: string): number {
    let hash = 5381;
    for (let i = 0; i < str.length; i++) {
        hash = (hash * 33) ^ str.charCodeAt(i);
    }
    // Ensure positive integer
    return hash >>> 0;
}

const colors = [
    '#8AB4F8',
    '#F28B82',
    '#FDD663',
    '#81C995',
    '#FF8BCB',
    '#C58AF9',
    '#78D9EC',
    //'#FCAD70'
]

export function getAvatarColor(name: string): string {
    const hash = hashCode(name)
    const colorIndex = hash % colors.length
    let colorIndex2 = colorIndex
    let index = 0
    while (colorIndex2 == colorIndex) {
        let hash2 = hashCode(index++ + name)
        colorIndex2 = hash2 % colors.length
    }
    return `linear-gradient(45deg, ${colors[colorIndex]}, ${colors[colorIndex2]})`
}
