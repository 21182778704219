
export enum Period {
    ALL_TIME = "ALL_TIME",
    ONE_YEAR = "ONE_YEAR",
    THREE_MONTHS = "THREE_MONTHS",
    ONE_MONTH = "ONE_MONTH",
    THIRTY_DAYS = "THIRTY_DAYS",
    SEVEN_DAYS = "SEVEN_DAYS",
    ONE_DAY = "ONE_DAY",
    CUSTOM = "CUSTOM",
}

export const periodData: PeriodData[] = [
    {period: Period.ALL_TIME, display: $localize`All`},
    {period: Period.ONE_YEAR, display: $localize`1Y`},
    //{period: Period.THREE_MONTHS, display: $localize`3M`},
    {period: Period.ONE_MONTH, display: $localize`1M`},
    {period: Period.THIRTY_DAYS, display: $localize`30D`},
    //{period: Period.SEVEN_DAYS, display: $localize`7D`},
    {period: Period.ONE_DAY, display: $localize`1D`},
    {period: Period.CUSTOM, display: $localize`Custom`},
]

export type PeriodData = {
    period: Period,
    display: string,
}

export type DatedPeriod = {
    period: Period,
    date: Date,
    index: number,
    dateRange: [Date, Date],
}

export function datedPeriod(period: Period, date: Date = new Date(), index: number = 0, endDate?: Date): DatedPeriod {
    let dateRange: [Date, Date];
    switch (period) {
        case Period.ALL_TIME:
            dateRange = [new Date(0, 0, 1), date];
            break;
        case Period.ONE_YEAR:
            const startOfYear = new Date(date.getFullYear() - index, 0, 1);
            const endOfYear = new Date(date.getFullYear() - index + 1, 0, 0);
            dateRange = [startOfYear, endOfYear];
            break;
        case Period.THREE_MONTHS:
            const startOfThreeMonths = new Date(date.getFullYear(), date.getMonth() - 2 - (3 * index), 1);
            const endOfThreeMonths = new Date(startOfThreeMonths.getFullYear(), startOfThreeMonths.getMonth() + 3, 0);
            dateRange = [startOfThreeMonths, endOfThreeMonths];
            break;
        case Period.ONE_MONTH:
            const startOfMonth = new Date(date.getFullYear(), date.getMonth() - index, 1);
            const endOfMonth = new Date(startOfMonth.getFullYear(), startOfMonth.getMonth() + 1, 0);
            dateRange = [startOfMonth, endOfMonth];
            break;
        case Period.THIRTY_DAYS:
            const startOfThirtyDays = new Date(date);
            startOfThirtyDays.setDate(date.getDate() - 29 - (30 * index));
            const endOfThirtyDays = new Date(startOfThirtyDays);
            endOfThirtyDays.setDate(startOfThirtyDays.getDate() + 29);
            dateRange = [startOfThirtyDays, endOfThirtyDays];
            break;
        case Period.SEVEN_DAYS:
            const startOfSevenDays = new Date(date);
            startOfSevenDays.setDate(date.getDate() - 6 - (7 * index));
            const endOfSevenDays = new Date(startOfSevenDays);
            endOfSevenDays.setDate(startOfSevenDays.getDate() + 6);
            dateRange = [startOfSevenDays, endOfSevenDays];
            break;
        case Period.ONE_DAY:
            const startOfDay = new Date(date);
            startOfDay.setDate(date.getDate() - index);
            const endOfDay = new Date(startOfDay);
            dateRange = [startOfDay, endOfDay];
            break;
        case Period.CUSTOM:
            dateRange = [date, endDate || date];
            break;
    }
    return {period, date, index, dateRange};
}

export function datedPeriodToString(datedPeriod: DatedPeriod): string {
    const monthDayShortOptions: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric' };
    const currentYear = new Date().getFullYear();
    const startYear = datedPeriod.dateRange[0].getFullYear();
    const endYear = datedPeriod.dateRange[1].getFullYear();

    switch (datedPeriod.period) {
        case Period.ALL_TIME:
            return $localize`All Time`;
        case Period.ONE_YEAR:
            return startYear.toString();
        case Period.THREE_MONTHS:
            let monthStartFormat = datedPeriod.dateRange[0].toLocaleDateString('default', { month: 'long' });
            let monthEndFormat = datedPeriod.dateRange[1].toLocaleDateString('default', { month: 'long' });

            if (startYear != currentYear) {
                // Include the year for the starting date part of the range
                monthStartFormat += ` ${startYear}`;
            }
            if (endYear != currentYear) {
                // For THREE_MONTHS or if the end date's year is different from the current year, include the year at the end part
                monthEndFormat += ` ${endYear}`;
            }
            return `${monthStartFormat} - ${monthEndFormat}`;
        case Period.ONE_MONTH:
            // Show just the month if it's within the current year; otherwise, include the year
            if (startYear == currentYear) {
                return datedPeriod.dateRange[0].toLocaleDateString('default', { month: 'long' });
            }
            return datedPeriod.dateRange[0].toLocaleDateString('default', { month: 'long', year: 'numeric' });
        case Period.THIRTY_DAYS:
        case Period.SEVEN_DAYS:
        case Period.CUSTOM:
            let startFormat = datedPeriod.dateRange[0].toLocaleDateString('default', monthDayShortOptions);
            let endFormat = datedPeriod.dateRange[1].toLocaleDateString('default', monthDayShortOptions);

            if (startYear != currentYear) {
                // Include the year for the starting date part of the range
                startFormat += ` ${startYear}`;
            }
            if (endYear != currentYear) {
                // For THREE_MONTHS or if the end date's year is different from the current year, include the year at the end part
                endFormat += ` ${endYear}`;
            }
            return `${startFormat} - ${endFormat}`;
        case Period.ONE_DAY:
            const monthDayLongOptions: Intl.DateTimeFormatOptions = { month: 'long', day: 'numeric' };
            return datedPeriod.dateRange[0].toLocaleDateString('default', startYear === currentYear ? monthDayLongOptions : { ...monthDayLongOptions, year: 'numeric' });
        default:
            return "Unknown period";
    }
}

export function periodToRequest(period: DatedPeriod): string {
    return `${period.period},${dateToString(period.date)},${period.index}` +
        (period.period === Period.CUSTOM ? `,${dateToString(period.dateRange[1])}` : '')
}

export function periodFromRequest(request: string): DatedPeriod {
    const parts = request.split(',');
    const period = parts[0] as Period || Period.THIRTY_DAYS;
    const date = parts.length > 1 ? new Date(parts[1]) : new Date();
    const index = parts.length > 2 ? parseInt(parts[2]) : 0;
    if (period === Period.CUSTOM) {
        const endDate = parts.length > 3 ? new Date(parts[3]) : new Date();
        return datedPeriod(period, date, index, endDate);
    }
    return datedPeriod(period, date, index);
}


export function dateToString(d: Date | string): string {
    const date = new Date(d)
    let month = (date.getMonth() + 1).toString()
    if (month.length < 2) {
        month = '0' + month
    }
    let day = date.getDate().toString()
    if (day.length < 2) {
        day = '0' + day
    }
    return `${date.getFullYear()}-${month}-${day}`
}
