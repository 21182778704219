!function (t, e) {
  "object" == typeof exports && "object" == typeof module ? module.exports = e() : "function" == typeof define && define.amd ? define("BrowserImageResizer", [], e) : "object" == typeof exports ? exports.BrowserImageResizer = e() : t.BrowserImageResizer = e();
}(self, () => (() => {
  "use strict";

  var t = {
      d: (e, a) => {
        for (var i in a) t.o(a, i) && !t.o(e, i) && Object.defineProperty(e, i, {
          enumerable: !0,
          get: a[i]
        });
      },
      o: (t, e) => Object.prototype.hasOwnProperty.call(t, e),
      r: t => {
        "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(t, Symbol.toStringTag, {
          value: "Module"
        }), Object.defineProperty(t, "__esModule", {
          value: !0
        });
      }
    },
    e = {};
  function a() {
    return document.createElement("canvas");
  }
  function i(t) {
    for (var e = atob(t.split(",")[1]), a = t.split(",")[0].split(":")[1].split(";")[0], i = new ArrayBuffer(e.length), o = new Uint8Array(i), r = 0; r < e.length; r++) o[r] = e.charCodeAt(r);
    return new Blob([i], {
      type: a
    });
  }
  function o(t, e) {
    var a = e.width / e.height,
      i = Math.min(e.width, t.maxWidth, a * t.maxHeight);
    return t.maxSize > 0 && t.maxSize < e.width * e.height / 1e3 && (i = Math.min(i, Math.floor(1e3 * t.maxSize / e.height))), t.scaleRatio && (i = Math.min(i, Math.floor(t.scaleRatio * e.width))), t.debug && (console.log("browser-image-resizer: original image size = " + e.width + " px (width) X " + e.height + " px (height)"), console.log("browser-image-resizer: scaled image size = " + i + " px (width) X " + Math.floor(i / a) + " px (height)")), i <= 0 && (i = 1, console.warn("browser-image-resizer: image size is too small")), i;
  }
  function r(t, e) {
    var a = document.createElement("canvas"),
      i = e.outputWidth / t.width;
    a.width = t.width * i, a.height = t.height * i;
    var o = t.getContext("2d").getImageData(0, 0, t.width, t.height),
      r = a.getContext("2d").createImageData(a.width, a.height);
    return function (t, e, a) {
      function i(t, e, a, i, o, r) {
        var n = 1 - o,
          d = 1 - r;
        return t * n * d + e * o * d + a * n * r + i * o * r;
      }
      var o, r, n, d, h, g, c, l, m, f, s, u, w, p, y, v, b, x, M;
      for (o = 0; o < e.height; ++o) for (n = o / a, d = Math.floor(n), h = Math.ceil(n) > t.height - 1 ? t.height - 1 : Math.ceil(n), r = 0; r < e.width; ++r) g = r / a, c = Math.floor(g), l = Math.ceil(g) > t.width - 1 ? t.width - 1 : Math.ceil(g), m = 4 * (r + e.width * o), f = 4 * (c + t.width * d), s = 4 * (l + t.width * d), u = 4 * (c + t.width * h), w = 4 * (l + t.width * h), p = g - c, y = n - d, v = i(t.data[f], t.data[s], t.data[u], t.data[w], p, y), e.data[m] = v, b = i(t.data[f + 1], t.data[s + 1], t.data[u + 1], t.data[w + 1], p, y), e.data[m + 1] = b, x = i(t.data[f + 2], t.data[s + 2], t.data[u + 2], t.data[w + 2], p, y), e.data[m + 2] = x, M = i(t.data[f + 3], t.data[s + 3], t.data[u + 3], t.data[w + 3], p, y), e.data[m + 3] = M;
    }(o, r, i), a.getContext("2d").putImageData(r, 0, 0), a;
  }
  function n(t) {
    var e = document.createElement("canvas");
    return e.width = t.width / 2, e.height = t.height / 2, e.getContext("2d").drawImage(t, 0, 0, e.width, e.height), e;
  }
  t.r(e), t.d(e, {
    readAndCompressImage: () => h
  });
  var d = {
    quality: .5,
    maxWidth: 800,
    maxHeight: 600,
    autoRotate: !0,
    debug: !1,
    mimeType: "image/jpeg"
  };
  function h(t, e) {
    return new Promise(function (h, g) {
      var c = document.createElement("img"),
        l = new FileReader(),
        m = Object.assign({}, d, e);
      l.onload = function (t) {
        c.onerror = function () {
          g("cannot load image.");
        }, c.onload = function () {
          var t = {
            img: c,
            config: m
          };
          try {
            var e = function () {
              var t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {},
                e = t.img,
                d = t.config,
                h = (t.orientation, a());
              h.width = e.width, h.height = e.height;
              var g = h.getContext("2d");
              "image/jpeg" === d.mimeType && (g.fillStyle = "#ffffff", g.fillRect(0, 0, h.width, h.height), g.save()), g.drawImage(e, 0, 0), g.restore();
              for (var c = o(d, h); h.width >= 2 * c;) h = n(h);
              h.width > c && (h = r(h, Object.assign(d, {
                outputWidth: c
              })));
              var l = h.toDataURL(d.mimeType, d.quality);
              return "function" == typeof d.onScale && d.onScale(l), i(l);
            }(t);
            h(e);
          } catch (t) {
            g(t);
          }
        }, c.src = t.target.result;
      };
      try {
        l.onerror = function () {
          g("cannot read image file.");
        }, l.readAsDataURL(t);
      } catch (t) {
        g(t);
      }
    });
  }
  return e;
})());