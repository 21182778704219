import {Injectable} from "@angular/core";
import {Subject} from "rxjs";
import {environment} from "../../../environments/environment";
import {
    MessageResponse,
    NotificationResponse, PlayUpdateData,
} from "../../model/responses";
import {AuthedUser, AuthedUserService} from "../authed-user.service";
import {UserService} from "./user.service";

@Injectable()
export class EventService {

    private webSocketUrl = `${environment.apiUrl
        .replace('https', 'wss')
        .replace('http', 'ws')
    }/events`

    private webSocket?: WebSocket;

    private subscriptions: string[] = []

    private connecting = false
    private reconnect = false

    notificationSubject = new Subject<NotificationResponse>()
    messageSubject = new Subject<MessageResponse>()
    playUpdateSubject = new Subject<PlayUpdateData>()
    errorSubject = new Subject<string>()

    lastPong = Date.now()

    constructor(
        authedUserService: AuthedUserService,
        private userService: UserService
    ) {
        console.log('Initializing event service')

        setTimeout(() => {
            let authedUserPresent = false
            authedUserService.getAuthedUserObservable().subscribe(authedUser => {
                if (authedUser?.auth) {
                    console.log('Authed user present')
                    if (!authedUserPresent || !this.webSocket) {
                        this.createSocket(authedUser)
                        authedUserPresent = true
                    }
                } else {
                    console.log('Authed user not present')
                    this.closeSocket()
                    authedUserPresent = false
                }
            })
        }, 1000)

        setInterval(() => {
            if (Date.now() - this.lastPong < 25_000) {
                console.log('Pong ok')
            } else {
                console.log('No pong received in 60 seconds, closing socket')
                this.closeSocket()
                const authedUser = authedUserService.getAuthedUser()
                if (authedUser) {
                    console.log('Reconnecting')
                    this.createSocket(authedUser)
                }
            }
        }, 20_000)
    }

    private createSocket(authedUser: AuthedUser) {
        if (this.webSocket) {
            if (this.webSocket.readyState === WebSocket.OPEN) {
                console.log('Socket already open')
                return
            }
            console.log('Closing socket before starting event service')
            this.closeSocket()
        }
        this.connecting = true
        console.log('Starting event service')
        const webSocket = new WebSocket(this.webSocketUrl)
        this.reconnect = true
        webSocket.onopen = () => {
            this.connecting = false
            webSocket.send(JSON.stringify({
                type: 'AUTHENTICATE',
                token: authedUser.auth!.token.token
            }))
        }
        webSocket.onerror = (error) => {
            console.error('Websocket error:', error)
        }
        webSocket.onmessage = (message) => {
            this.handleMessage(JSON.parse(message.data))
        }
        webSocket.onclose = () => {
            console.log('Websocket closed')
            if (this.reconnect && !this.connecting) {
                setTimeout(() => this.createSocket(authedUser), 1000)
            }
            this.connecting = false
        }
        this.webSocket = webSocket
        this.schedulePing(webSocket, authedUser)
    }

    private schedulePing(webSocket: WebSocket, authedUser: AuthedUser) {
        setTimeout(() => {
            if (webSocket.readyState === WebSocket.OPEN) {
                webSocket.send(JSON.stringify({
                    type: 'PING',
                }))
                this.schedulePing(webSocket, authedUser)
            } else if (this.reconnect && !this.connecting) {
                setTimeout(() => this.createSocket(authedUser), 1000)
            }
        }, 15000)
    }

    private closeSocket() {
        console.log('Closing socket')
        //this.reconnect = false
        if (this.webSocket) {
            this.webSocket.close()
            this.webSocket = undefined
        }
    }

    subscribe(eventType: string) {
        this.subscriptions = this.subscriptions.filter(s => s !== eventType)
        this.subscriptions.push(eventType)
        if (this.webSocket && this.webSocket.readyState === WebSocket.OPEN) {
            this.webSocket.send(JSON.stringify({
                type: 'SUBSCRIBE',
                eventType: eventType
            }))
        } else {
            setTimeout(() => this.subscribe(eventType), 1000)
        }
    }

    unsubscribe(eventType: string) {
        this.subscriptions = this.subscriptions.filter(s => s !== eventType)
        if (this.webSocket && this.webSocket.readyState === WebSocket.OPEN) {
            this.webSocket.send(JSON.stringify({
                type: 'UNSUBSCRIBE',
                eventType: eventType
            }))
        } else {
            setTimeout(() => this.unsubscribe(eventType), 1000)
        }
    }

    handleMessage(message: TypedMessage) {
        console.log('Received message: ', message)
        switch (message.type) {
            case 'PONG':
                this.lastPong = Date.now()
                break
            case 'AUTHENTICATED':
                console.log('Authenticated!')
                this.subscriptions.forEach(subscription => {
                    this.webSocket!.send(JSON.stringify({
                        type: 'SUBSCRIBE',
                        eventType: subscription
                    }))
                })
                break
            case 'NOTIFICATION':
                this.notificationSubject.next(message["data"])
                break
            case 'MESSAGE':
                this.messageSubject.next(message["data"])
                break
            case 'PLAY_UPDATE':
                this.playUpdateSubject.next(message["data"])
                break
            case 'ERROR':
                console.error('Error: ', message["message"])
                if (message["message"] === 'Invalid JWT token') {
                    // Fire refresh token mechanism
                    this.userService.getMe().subscribe()
                    this.reconnect = false
                }
                this.errorSubject.next(message["message"])
                break
        }
    }
}

export type TypedMessage = {
    type: string,
    [key: string]: any
}
